import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";

import App from "./containers/App";
import "./index.css";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";

const innerTheme = createMuiTheme({
	palette: {
		primary: {
			main: "#964c59",
		},
	},
});

ReactDOM.render(
	<ThemeProvider theme={innerTheme}>
		<Router>
			<App />
		</Router>
	</ThemeProvider>,
	document.getElementById("root")
);
