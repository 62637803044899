import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'

const Pagination = ({ isDisabled, currentPage, perPage, totalPages, goBack, goForward }) => {
  const isFirst = currentPage === 1
  const isLast = currentPage === totalPages

  return (
    <ul className="pagination">
      <li><IconButton onClick={goBack} disabled={isDisabled || isFirst}><LeftIcon /></IconButton></li>
      <li className="page-number">{currentPage} / {totalPages}</li>
      <li><IconButton onClick={goForward} disabled={isDisabled || isLast}><RightIcon /></IconButton></li>
    </ul>
  )
}

export default Pagination
