import React, { Component } from 'react'

import clone from 'clone';
import {
    CircularProgress,
    Button
} from '@material-ui/core'

import ContainerWithRightSidebar from '../components/ContainerWithRightSidebar'
import ContributionKeywordsActions from '../components/ContributionKeywordsActions'
import ToolbarToFilterAndSort from '../components/ToolbarToFilterAndSort'
import ContributionsGrid from '../components/ContributionsGrid'
import ContributionDetails from '../components/ContributionDetails'
import Pagination from '../components/Pagination'
import AddToCollection from '../components/AddToCollection';
import RemoveFromCollection from "../components/RemoveFromCollection";
import api from '../api'
import authentication from '../authentication'
import DisplayError from '../components/DisplayError'
import ContributionDarwin from '../ContributionDarwin'


const dateSorters = [{
  title: 'Newer first',
  value: 'desc'
}, {
  title: 'Older first',
  value: 'asc'
}, {
  title: 'Best match',
  value: 'best_match'
}]

const ratingFilters = [{
  title: 'All',
  value: 'all'
}, {
  title: '5',
  value: '5'
}, {
  title: '4',
  value: '4'
}, {
  title: '3',
  value: '3'
}, {
  title: '2',
  value: '2'
}, {
  title: '1',
  value: '1',
}, {
  title: 'Not rated',
  value: '0'
}]

class CollectionContributions extends Component {
  constructor(props) {
    super(props)

    const urlParams = new URLSearchParams(this.props.location.search)

    let searchParams = {}
    if (urlParams.get('query')) {
      searchParams.query = urlParams.get('query')
    }

    if (urlParams.get('by_user')) {
      searchParams.by_user = urlParams.get('by_user')
    }

    this.state = {
      isLoading: true,
      currentPage: urlParams.get('page_nr') || 1,
      perPage: urlParams.get('page_limit') || 200,
      totalResults: null,
      totalPages: null,
      contributions: [],
      selectedContributions: [],
      sorters: {
        'sort-by-date': {
          key: 'sort_order',
          selectedValue: urlParams.get('sort_order') || 'desc',
          options: dateSorters
        }
      },
      filters: {
        'filter-by-rating': {
          title: 'Rating',
          key: 'rating_admin_users',
          selectedValue: urlParams.get('rating_admin_users') || 'all',
          options: ratingFilters
        }
      },
      search: {
        title: urlParams.get('searchTitle') || '',
        searchParams: searchParams
      },
      error: {
        show: false,
        message: "",
        code: 0
      }
    }

    this.getContributions = this.getContributions.bind(this)
    this.fetchContributions = this.fetchContributions.bind(this)
    this.setSorter = this.setSorter.bind(this)
    this.setSearch = this.setSearch.bind(this)
    this.prevPage = this.prevPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.selectContribution = this.selectContribution.bind(this)
    this.changeTofullscreenMode = this.changeTofullscreenMode.bind(this)
    this.updateContributions = this.updateContributions.bind(this)
    this.updateAllContributions = this.updateAllContributions.bind(this)
    this.updateContribution = this.updateContribution.bind(this)
  }

  componentDidMount() {
    this.props.changeTitle(this.props.match.params.collectionSlug)

    this.fetchContributions()
  }

  getContributions() {
    const params = {
      page_nr: this.state.currentPage,
      page_limit: this.state.perPage,
      sort_by: 'created_on',
      collection_slug: this.props.match.params.collectionSlug,
      ...Object.entries(this.state.filters).reduce((newFilters, filter) => {
        if (filter[1].selectedValue !== 'all') {
          return {
            ...newFilters,
            [filter[1].key]: filter[1].selectedValue
          }
        } else {
          return newFilters
        }
      }, {}),
      ...Object.entries(this.state.sorters).reduce((newSorters, sorter) => {
        return {
          ...newSorters,
          [sorter[1].key]: sorter[1].selectedValue
        }
      }, {}),
      ...this.state.search.searchParams
    }

    return api.getContributions(params).then(data => {
      const perPage = parseInt(data.per_page, 10)
      const totalResults = parseInt(data.total_count, 10)
      //TODO: Reduce contributions object
      const contributions = data.data || []

      const paramsUrl = Object.entries(params).map(([key, value]) => `${key}=${value}`).join('&')

      this.props.history.replace(`?${paramsUrl}&searchTitle=${this.state.search.title}`)

      this.setState({
        isLoading: false,
        currentPage: parseInt(data.page, 10),
        perPage,
        totalResults,
        totalPages: Math.ceil(totalResults / perPage),
        contributions,
        error: {
            show: contributions.length === 0,
            message: "No images found in collection",
            code: 404
        }
      })
    })
  }

  fetchContributions() {
    this.setState({ isLoading: true, contributions: [], selectedContributions: [] }, () => {
      this.getContributions()
    })
  }

  setSorter(sortId, selectedValue) {
    const sorters = Object.keys(this.state.sorters).reduce((newSorters, id) => {
      if (id !== sortId) {
        return {...newSorters, [id]: this.state.sorters[id] }
      } else {
        return {...newSorters, [id]: {...this.state.sorters[id], selectedValue }}
      }
    }, {})

    this.setState({ sorters, currentPage: 1 }, this.fetchContributions)
  }

  setSearch(selectedValue) {
    this.setState({ search: selectedValue, currentPage: 1 }, this.fetchContributions)
  }

  prevPage() {
    const currentPage = this.state.currentPage - 1

    this.setState({ currentPage }, this.fetchContributions)
  }

  nextPage() {
    const currentPage = this.state.currentPage + 1

    this.setState({ currentPage }, this.fetchContributions)
  }

  selectContribution(slug, selectMode) {
    const isSelected = this.state.selectedContributions.indexOf(slug) > -1

    switch (selectMode) {
      case 'single': {
        this.setState({ selectedContributions: isSelected ? [] : [slug] })
        break;
      }
      case 'single-multiple': {
        let selectedContributions = []

        if (isSelected) {
          selectedContributions = this.state.selectedContributions.filter(c => c !== slug)
        } else {
          selectedContributions = [slug, ...this.state.selectedContributions]
        }

        this.setState({ selectedContributions })
        break;
      }
      case 'multiple': {
        const firstIndex = this.state.contributions.findIndex(c => c.slug === this.state.selectedContributions[0])
        const lastIndex = this.state.contributions.findIndex(c => c.slug === slug)

        const multipleSelectedContributions = this.state.contributions.slice(firstIndex < lastIndex ? firstIndex : lastIndex, lastIndex > firstIndex ? lastIndex + 1 : firstIndex).map(c => c.slug)

        const selectedContributions = [...multipleSelectedContributions, ...this.state.selectedContributions.filter(c => !multipleSelectedContributions.includes(c.slug))]

        this.setState({ selectedContributions })
        break;
      }
      default:
    }
  }

  changeTofullscreenMode(slug, force) {
    if (!force && (this.state.selectedContributions.length !== 1 || slug !== this.state.selectedContributions[0])) {
      return false
    }

    const urlParams = new URLSearchParams(this.props.location.search)
    this.props.history.push(`/collections/${this.props.match.params.collectionSlug}/contributions/${slug}?${urlParams.toString()}`,
      this.state.contributions
    )

    return false
  }

  updateAllContributions() {
    const ids = this.state.contributions.map(c => c.django_id)
    const actions = ['explicitly_level__safe', 'model_release__not_needed', 'status__publish']

    return this.updateContributions(ids, actions)
  }

  updateContributions(ids, actions) {
    const updateParams = {
      ids: ids.join(),
      actions_to_update: actions.join()
    }

    return api.bulkUpdateContributions(updateParams)
    .then(() => {
      this.getContributions()
      //TODO: Update contributions state
      //TODO: Show snackbar
    })
  }

  updateContribution(slug, params, callback) {
    return api.updateContribution(slug, params)
    .then(updatedContribution => {
      const contributions = this.state.contributions.map(c => {
        if (~~c.django_id === ~~updatedContribution.id) {
          c.categories = updatedContribution.category
          c.tags_admin_high = updatedContribution.tags_admin_high
          c.tags = updatedContribution.tags
          c.tags_machine = updatedContribution.tags_machine
        }

        return c
      })

      this.setState({ contributions })

      if(typeof callback === "function"){
        callback();
      }
    })
  }

  removeFromCollection = () => {
    const userId = authentication.getUser().id
    this.setState({
        showRemoveFromCollectionDialog: false
    });
    api.removeFromCollection(userId, this.props.match.params.collectionSlug, this.state.selectedContributions).then(res => {
        this.setState({
            isLoading: true,
            contributions: [],
            selectedContributions: []
        })
        this.getContributions();
    })
  }

  addContributionToCollection = (selectedCollection) => {
    const userId = authentication.getUser().id
    return api.addToCollection(userId, selectedCollection, this.state.selectedContributions)
  }

  setFilter = (filterId, selectedValue) => {
    const filters = Object.keys(this.state.filters).reduce((newFilters, id) => {
      if (id !== filterId) {
        return {...newFilters, [id]: this.state.filters[id] }
      } else {
        return {...newFilters, [id]: {...this.state.filters[id], selectedValue }}
      }
    }, {})

    this.setState({ filters, currentPage: 1, isLoading: true, error: { show: false } }, this.fetchContributions)
  }


  updateContributionKeywords = (type, resultTags, callback) => {
    const selectedContributionsObjects = this.state.contributions.filter(c => this.state.selectedContributions.includes(c.slug))

    let ids = [];
    let actions = []
    selectedContributionsObjects.forEach((contribution, index) => {
      ids.push(contribution.django_id);
    });

    if(type === "delete"){
      actions = ["tags__remove_machine", "tags__remove_human", "tags__remove_admin"]
    } else {
      actions = ["tags__add_admin"];
    }
    this.updateContributions(ids, actions, {
      tags: resultTags.join()
    }).then(() => {
      setTimeout(() => {
        callback()
        this.getContributions();
      }, 2000);
    })
  }

  render() {
    const {
      isLoading,
      currentPage,
      perPage,
      totalPages,
      contributions,
      selectedContributions,
      sorters,
      search,
      filters
    } = this.state

    const { categories } = this.props

    const {
      setSorter,
      setSearch,
      prevPage,
      nextPage,
      selectContribution,
      changeTofullscreenMode,
      updateContribution,
      addContributionToCollection,
      setFilter,
      updateContributionKeywords
    } = this

    const selectedContributionsObjects = contributions.filter(c => selectedContributions.includes(c.slug))

    return (
      <div>
        <ToolbarToFilterAndSort filters={filters} setFilter={setFilter} sorters={sorters} setSorter={setSorter} search={search} setSearch={setSearch}/>

        {this.state.error.show ? (
          <DisplayError details={this.state.error.details} message={this.state.error.message} code={this.state.error.code} />
        ) : (
        <ContainerWithRightSidebar
          stickyOffset={120}
          content={
            <div>
              {isLoading ? (
                <div className="loader">
                  <CircularProgress />
                </div>
              ) : (
                <ContributionsGrid
                  contributions={contributions}
                  selectedContributions={selectedContributions}
                  selectContribution={selectContribution}
                  changeTofullscreenMode={changeTofullscreenMode}
                />
              )}

              {totalPages !== null ? (
                <Pagination
                  isDisabled={isLoading}
                  currentPage={currentPage}
                  perPage={perPage}
                  totalPages={totalPages}
                  goBack={prevPage}
                  goForward={nextPage}
                />
              ) : null}
            </div>
          }
          sidebar={
            <div>
              {selectedContributions.length > 0 ? (
                <div style={{ padding: 10 }}>
                    <RemoveFromCollection open={this.state.showRemoveFromCollectionDialog} onSubmit={this.removeFromCollection} onCancel={() => this.setState({showRemoveFromCollectionDialog:false})} />
                    <AddToCollection addContributionsToCollection={addContributionToCollection}/>
                    <Button fullWidth style={{ color: "#F44336", marginTop: 10 }} color={"#F44336"} onClick={() => this.setState({showRemoveFromCollectionDialog: true})}>Remove from collection</Button>
                    { /* <ContributionActions contributions={selectedContributionsObjects} updateContributions={updateContributions}/> */ }
                </div>
              ) : null}

              {selectedContributions.length === 1 ? (
                <div>
                  <ContributionDetails groups={ContributionDarwin.collections} darwinKey={'collections'} categories={categories} contribution={selectedContributionsObjects[0]} updateContribution={updateContribution} setSearch={setSearch}/>
                </div>
              ) : null}

              {selectedContributions.length > 1 ? (
                <div>
                  <ContributionKeywordsActions
                    buttonLabel={'Delete keywords'}
                    title={'Delete keywords'}
                    type={'delete'}
                    updateKey={'tags'}
                    selectedItems={selectedContributionsObjects}
                    updateContribution={updateContributionKeywords}
                  />
                  <ContributionKeywordsActions
                    buttonLabel={'Add keywords'}
                    title={'Add keywords'}
                    type={'add'}
                    updateKey={'tags'}
                    selectedItems={selectedContributionsObjects}
                    updateContribution={updateContributionKeywords}
                  />
                </div>
              ) : null}
            </div>
          }
          showSidebar={selectedContributions.length > 0}
        />
        )}
      </div>
    )
  }
}

export default CollectionContributions
