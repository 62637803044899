import React from "react";

function Easter(props) {
	const [value, setValue] = React.useState(null);
	const [showGame, setShowGame] = React.useState(false);
	const iframe = React.createRef();

	React.useEffect(() => {
		if (value === "snake") {
			setShowGame(true);
		}
		if (iframe.current) {
			iframe.current.focus();
			iframe.current.click();
		}
	}, [value, iframe]);

	if (showGame) {
		return (
			<iframe
				src="snake.html"
				autoFocus
				ref={iframe}
				style={{ width: "100%", height: 400, border: 0 }}></iframe>
		);
	}

	return (
		<div className="Easter" style={{ maxHeight: 0, overflow: "hidden" }}>
			<input
				type="text"
				value={value}
				autoFocus
				onChange={event => setValue(event.target.value)}
			/>
		</div>
	);
}

export default Easter;
