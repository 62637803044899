import React, { Component } from 'react'

import { List } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'

const imageStatus = [
  { name: 'Draft', slug: 'draft', action: 'status__draft' },
  { name: 'Pending review', slug: 'pending-review', action: 'status__pending_review' },
  { name: 'Published', slug: 'published', action: 'status__publish' },
  { name: 'Deleted', slug: 'deleted', action: 'status__delete' }
]

const explicitnessLevel = [
  { name: 'Inappropriate', slug: 'inappropriate', action: 'explicitly_level__inappropriate' },
  { name: 'Allowing', slug: 'allowing', action: 'explicitly_level__allowing' },
  { name: 'Moderate', slug: 'moderate', action: 'explicitly_level__moderate' },
  { name: 'Safe', slug: 'safe', action: 'explicitly_level__safe' }
]

const modelReleaseStatus = [
  { name: 'Needed', slug: 'needed', action: 'model_release__needed' },
  { name: 'Not needed', slug: 'not-needed', action: 'model_release__not_needed' },
  { name: 'Released', slug: 'released', action: 'model_release__released' }
]

class ContributionActions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedImageStatus: this.getAvailableSlug(imageStatus, this.props.contributions[0].status),
      selectedExplicitnessLevel: this.getAvailableSlug(explicitnessLevel, this.props.contributions[0].explicitly_level),
      selectedModelReleaseStatus: this.getAvailableSlug(modelReleaseStatus, this.props.contributions[0].release_status)
    }

    this.selectImageStatus = this.selectImageStatus.bind(this)
    this.selectExplicitnessLevel = this.selectExplicitnessLevel.bind(this)
    this.selectModelReleaseStatus = this.selectModelReleaseStatus.bind(this)
    this.setSafeAndMrNeeded = this.setSafeAndMrNeeded.bind(this)
    this.setSafeAndMrNotNeeded = this.setSafeAndMrNotNeeded.bind(this)
    this.setModerateAndMrNeeded = this.setModerateAndMrNeeded.bind(this)
    this.updateContributions = this.updateContributions.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.contributions.length === 1) {
      this.setState({
        selectedImageStatus: this.getAvailableSlug(imageStatus, nextProps.contributions[0].status),
        selectedExplicitnessLevel: this.getAvailableSlug(explicitnessLevel, nextProps.contributions[0].explicitly_level),
        selectedModelReleaseStatus: this.getAvailableSlug(modelReleaseStatus, nextProps.contributions[0].release_status)
      })
    } else {
      this.setState({
        isLoading: false,
        selectedImageStatus: null,
        selectedExplicitnessLevel: null,
        selectedModelReleaseStatus: null
      })
    }
  }

  getAvailableSlug(array, slug) {
    if (array.findIndex(arr => arr.slug === slug) === -1) {
      return null
    }

    return slug
  }

  selectImageStatus(e, i, selectedImageStatus) {
    this.setState({ selectedImageStatus: e.target.value })
  }

  selectExplicitnessLevel(e, i, selectedExplicitnessLevel) {
    this.setState({ selectedExplicitnessLevel: e.target.value })
  }

  selectModelReleaseStatus(e, i, selectedModelReleaseStatus) {
    this.setState({ selectedModelReleaseStatus: e.target.value })
  }

  setSafeAndMrNeeded() {
    this.setState({
      selectedImageStatus: imageStatus[1].slug,
      selectedExplicitnessLevel: explicitnessLevel[3].slug,
      selectedModelReleaseStatus: modelReleaseStatus[0].slug
    })
  }

  setSafeAndMrNotNeeded() {
    this.setState({
      selectedImageStatus: imageStatus[2].slug,
      selectedExplicitnessLevel: explicitnessLevel[3].slug,
      selectedModelReleaseStatus: modelReleaseStatus[1].slug
    })
  }

  setModerateAndMrNeeded() {
    this.setState({
      selectedImageStatus: imageStatus[1].slug,
      selectedExplicitnessLevel: explicitnessLevel[2].slug,
      selectedModelReleaseStatus: modelReleaseStatus[0].slug
    })
  }

  updateContributions() {
    this.setState({ isLoading: true })

    const ids = this.props.contributions.map(c => c.django_id)

    const actions = [
      imageStatus.find(obj => obj.slug === this.state.selectedImageStatus).action,
      explicitnessLevel.find(obj => obj.slug === this.state.selectedExplicitnessLevel).action,
      modelReleaseStatus.find(obj => obj.slug === this.state.selectedModelReleaseStatus).action
    ]

    this.props.updateContributions(ids, actions).then(() => this.setState({ isLoading: false }))
  }

  render() {
    const { isLoading, selectedImageStatus, selectedExplicitnessLevel, selectedModelReleaseStatus } = this.state
    const {
      selectImageStatus,
      selectExplicitnessLevel,
      selectModelReleaseStatus,
      setSafeAndMrNotNeeded,
      updateContributions
    } = this

    return (
      <List>
        <div className="list-selects">
          <FormControl style={{ width: "100%", marginTop: 15 }}>
            <InputLabel>Image status</InputLabel>
            <Select
              style={{width: '100%'}}
              value={selectedImageStatus || ''}
              onChange={selectImageStatus}
            >
              {imageStatus.map(item => <MenuItem key={item.slug} value={item.slug}>{item.name}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl style={{ width: "100%", marginTop: 20 }}>
            <InputLabel>Explicitness level</InputLabel>
            <Select
              style={{width: '100%'}}
              value={selectedExplicitnessLevel || ''}
              onChange={selectExplicitnessLevel}
            >
              {explicitnessLevel.map(item => <MenuItem key={item.slug} value={item.slug}>{item.name}</MenuItem>)}
            </Select>
          </FormControl>

          <FormControl style={{ width: "100%", marginTop: 20, marginBottom: 10 }}>
            <InputLabel>Model release status</InputLabel>
            <Select
              value={selectedModelReleaseStatus || ''}
              onChange={selectModelReleaseStatus}
            >
              {modelReleaseStatus.map(item => <MenuItem key={item.slug} value={item.slug}>{item.name}</MenuItem>)}
            </Select>
          </FormControl>

          { /* <containedButton className="button" onClick={setSafeAndMrNeeded} label="Safe & MR Needed" fullWidth />  */ }
          { /* <containedButton className="button" onClick={setModerateAndMrNeeded} label="Moderate & MR Needed" fullWidth /> */ }
          <Button variant="outlined" className="button" onClick={setSafeAndMrNotNeeded} label="Safe & MR Not Needed" fullWidth>
            Safe & MR Not Needed
          </Button>
          <Button
            className="button"
            label="Save"
            color="primary"
            variant="contained"
            fullWidth
            onClick={updateContributions}
            disabled={!(selectedImageStatus && selectedExplicitnessLevel && selectedModelReleaseStatus) || isLoading}
          >Save</Button>
        </div>
      </List>
    )
  }
}

export default ContributionActions
