import React, { Component } from "react";
import {
    ListItemText,
    LinearProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Input,
    Checkbox,
} from "@material-ui/core";

class CollectionSettings extends Component {
    constructor(props){
        super(props);
        let setState = {}
        if(props.collection){
          setState.collection = props.collection;
        }
        if(props.categoriesData){
          setState.categoriesData = props.categoriesData;
        }
        if(props.collection && props.collection.collection_tags){
          setState.tags = props.collection.collection_tags.map(tag => tag.slug).join();
        }
        if(props.collection && props.collection.collection_image_type){
          setState.collection_image_type = props.collection.collection_image_type;
        }
        if(props.collection && props.collection.collection_categories){
          setState.categories = props.collection.collection_categories.map(cat => cat.id);
        }
        this.state = {
          collection: props.collection || "",
          categories: [],
          tags: "",
          collection_image_type: "",
          categoriesData: props.categoriesData || [],
          ...setState
        }
        console.log(this.state);
    }
    componentWillReceiveProps(props){
        let setState = {}
        if(props.categoriesData){
          setState.categoriesData = props.categoriesData;
        }
        if(Object.keys(setState)){
          this.setState(setState);
        }
    }
    selectCategory = (event) => {
      this.setState({ categories: event.target.value })
    }
    getCategory = (id) => {
      return this.state.categoriesData.find(cat => cat.id === id) || { name: id };
    }
    render(){
      if(!this.state.collection){
        return <div></div>
      }
      return (
        <div style={{ width: 300, display: "block"}}>
          <DialogTitle>Editing {this.state.collection.title}</DialogTitle>
          <DialogContent>

          { this.state.categoriesData.length ? (
            <FormControl style={{ width: "100%", display: "block" }}>
              <InputLabel>Categories</InputLabel>
              <Select
                multiple
                input={<Input id="select-multiple" style={{ width: "100%", display: "block" }} />}
                renderValue={selected => (
                  <span>{ selected.map(category => this.getCategory(category).name + ", ") }</span>
                )}
                style={{ width: "100%", marginTop: 16 }}
                value={this.state.categories}
                onChange={this.selectCategory}
              >
              {this.state.categoriesData.map(category => (
                <MenuItem key={category.id} value={category.id}>
                  <Checkbox checked={this.state.categories.indexOf(category.id) > -1} />
                  <ListItemText primary={category.name} />
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          ) : (<div><small>Loading categories...</small> <LinearProgress style={{marginTop: 6}} /></div>) }

            <FormControl style={{  marginTop: 10, width: "100%", display: "block" }}>
              <InputLabel>Type</InputLabel>
              <Select
                style={{ width: "100%", marginTop: 16 }}
                value={this.state.collection_image_type}
                onChange={ (event) => this.setState({ collection_image_type: event.target.value })}
              >
                <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"photo"}>Photos</MenuItem>
                  <MenuItem value={"illustration"} >Illustrations</MenuItem>
              </Select>
            </FormControl>
          <FormControl style={{ marginTop: 10, width: "100%" }}>
            <TextField label="Tags" style={{ width: "100%" }} value={this.state.tags} onChange={ (event) => this.setState({ tags: event.target.value })} />
          </FormControl>
          </DialogContent>
          <DialogActions>
          { this.props.onClose ? (<Button onClick={this.props.onClose} color="primary">
            Cancel
          </Button> ) : null }
          { this.props.onSave ? (<Button onClick={ () => this.props.onSave({ tags: this.state.tags, categories: this.state.categories, collection_image_type: this.state.collection_image_type })} color="primary">
            Save
          </Button> ) : null }
          </DialogActions>
        </div>
      )
    }
}



export default CollectionSettings;