import React, { Component } from 'react'

import {
  List,
  ListSubheader,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel
} from "@material-ui/core"

class EditDialogWithCheckboxes extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isDialogOpen: false,
      selectedItems: this.props.selectedItems || []
    }

    this.toggleDialog = this.toggleDialog.bind(this)
    this.checkCategory = this.checkCategory.bind(this)
    this.update = this.update.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selectedItems: nextProps.selectedItems })
  }

  toggleDialog() {
    if (this.state.isLoading) {
      return
    }

    this.setState({ isDialogOpen: !this.state.isDialogOpen })
  }

  checkCategory(category, isChecked) {
    if (isChecked) {
      this.setState({ selectedItems: [...this.state.selectedItems, category] })
    } else {
      this.setState({ selectedItems: this.state.selectedItems.filter(c => c.slug !== category.slug) })
    }
  }

  update() {
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true })

    this.props.updateContribution(this.props.contributionIds, this.state.selectedItems.map(c => c.id))
    .then(() => {
      this.setState({ isLoading: false, isDialogOpen: false })
    })
  }

  render() {
    const { isLoading, isDialogOpen, selectedItems } = this.state
    const { items, title } = this.props
    const { toggleDialog, update } = this

    return (
      <div>
        <List>
          <ListSubheader>Categories</ListSubheader>
          <div className="list-selects">
            <Button
              className="button"
              variant="contained"
              color="primary"
              label={this.props.buttonLabel || "Change categories"}
              fullWidth
              onClick={toggleDialog}
            >Change categories</Button>
          </div>
        </List>

        <Dialog
          autoScrollBodyContent
          open={isDialogOpen}
          onClose={toggleDialog}
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent style={{ width: 300, maxHeight: 450 }}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              items.map((category, i) => (
                <div key={i} style={{ display: "block" }}>
                  <FormControlLabel control={
                    <Checkbox
                      onChange={(e, isChecked) => this.checkCategory(category, isChecked)}
                      label={category.name}
                      checked={selectedItems && selectedItems.findIndex(cat => cat.slug === category.slug) !== -1}
                    /> } label={category.name} 
                  />
                </div>
            ))
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog}>Cancel</Button>
            <Button
              color="primary"
              variant="contained"
              onClick={update}
            >Update</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default EditDialogWithCheckboxes