import React, { Component } from "react";

import clone from "clone";

import {
	CircularProgress,
	IconButton,
	Divider,
	Select,
	MenuItem,
	FormControl,
	LinearProgress,
	List,
	InputLabel,
} from "@material-ui/core";

import DownloadIcon from "@material-ui/icons/CloudDownload";

import ContainerWithRightSidebar from "../components/ContainerWithRightSidebar";
import ToolbarToFilterAndSort from "../components/ToolbarToFilterAndSort";
import ContributionsGrid from "../components/ContributionsGrid";
import ContributionDetails from "../components/ContributionDetails";
import ContributionCategoriesActions from "../components/ContributionCategoriesActions";
import ContributionKeywordsActions from "../components/ContributionKeywordsActions";
import AddToCollection from "../components/AddToCollection";
import Pagination from "../components/Pagination";
import api from "../api";
import authentication from "../authentication";
import ContributionDarwin from "../ContributionDarwin";
import Easter from "./Easter";
import Cache from "../Cache";

const ratingFilters = [
	{
		title: "All",
		value: "all",
	},
	{
		title: "5",
		value: "5",
	},
	{
		title: "4",
		value: "4",
	},
	{
		title: "3",
		value: "3",
	},
	{
		title: "2",
		value: "2",
	},
	{
		title: "1",
		value: "1",
	},
	{
		title: "Not rated",
		value: "0",
	},
];
const maxRatingFilters = [
	{
		title: "All",
		value: "all",
	},
	{
		title: "5",
		value: "5",
	},
	{
		title: "4",
		value: "4",
	},
	{
		title: "3",
		value: "3",
	},
	{
		title: "2",
		value: "2",
	},
	{
		title: "1",
		value: "1",
	},
	{
		title: "Not rated",
		value: "0",
	},
];

const categoryFilters = [
	{
		title: "All",
		value: "all",
	},
	{
		title: "Uncategorized",
		value: "none",
	},
];

const imageTypeFilters = [
	{
		title: "All",
		value: "all",
	},
	{
		title: "Photo",
		value: "photo",
	},
	{
		title: "Illustration",
		value: "illustration",
	},
];

const dateSorters = [
	{
		title: "Newer first",
		value: "desc",
	},
	{
		title: "Older first",
		value: "asc",
	},
	{
		title: "Best match",
		value: "best_match",
	},
];

class Curation extends Component {
	constructor(props) {
		super(props);

		const urlParams = new URLSearchParams(this.props.location.search);

		let searchParams = {};
		if (urlParams.get("query")) {
			searchParams.query = urlParams.get("query");
		}

		if (urlParams.get("by_user")) {
			searchParams.by_user = urlParams.get("by_user");
		}

		this.state = {
			isLoading: true,
			currentPage: urlParams.get("page_nr") || 1,
			perPage: urlParams.get("page_limit") || 200,
			totalResults: null,
			totalPages: null,
			contributions: [],
			selectedContributions: [],
			filters: {
				"filter-by-category": {
					title: "Category",
					key: "categories",
					selectedValue: urlParams.get("categories") || "all",
					options: categoryFilters.concat(
						this.props.categories.map(c => ({ title: c.name, value: c.slug }))
					),
				},
				"filter-by-rating": {
					title: "Rating",
					key: "rating_admin_users",
					selectedValue: urlParams.get("rating_admin_users") || "all",
					options: ratingFilters,
				},
				"filter-by-max-rating": {
					title: "Max rating",
					key: "max_rating_admin_users",
					selectedValue: urlParams.get("max_rating_admin_users") || "all",
					options: maxRatingFilters,
				},
				"filter-by-image-type": {
					title: "Image type",
					key: "image_type",
					selectedValue: urlParams.get("image_type") || "all",
					options: imageTypeFilters,
				},
			},
			sorters: {
				"sort-by-date": {
					title: "Sort order",
					key: "sort_order",
					selectedValue: urlParams.get("sort_order") || "desc",
					options: dateSorters,
				},
			},
			search: {
				title: urlParams.get("searchTitle") || "",
				searchParams: searchParams,
			},
		};

		this.getContributions = this.getContributions.bind(this);
		this.fetchContributions = this.fetchContributions.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.setSorter = this.setSorter.bind(this);
		this.setSearch = this.setSearch.bind(this);
		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.selectContribution = this.selectContribution.bind(this);
		this.changeTofullscreenMode = this.changeTofullscreenMode.bind(this);
		this.updateContributions = this.updateContributions.bind(this);
		this.updateAllContributions = this.updateAllContributions.bind(this);
		this.updateContribution = this.updateContribution.bind(this);
		this.updateContributionCategories = this.updateContributionCategories.bind(
			this
		);
		this.addContributionsToCollection = this.addContributionsToCollection.bind(
			this
		);
		this.downloadContributions = this.downloadContributions.bind(this);
	}

	componentDidMount() {
		this.props.changeTitle("Curation");

		this.unmounted = false;
		this.fetchContributions();
	}

	componentWillUnmount() {
		this.unmounted = true;
	}

	componentWillReceiveProps(nextProps) {
		//TODO: Move categories to redux store some day...
		if (this.props.categories !== nextProps.categories) {
			const categories = nextProps.categories.map(c => ({
				id: c.id,
				title: c.name,
				value: c.slug,
			}));
			const categoriesFilter = { ...this.state.filters["filter-by-category"] };
			categoriesFilter.options = categoryFilters.concat(categories);

			this.setState({
				filters: {
					...this.state.filters,
					"filter-by-category": categoriesFilter,
				},
			});
		}
	}

	getContributions(cb) {
		const params = {
			page_nr: this.state.currentPage,
			page_limit: this.state.perPage,
			sort_by: "created_on",
			status: "published",
			...Object.entries(this.state.filters).reduce((newFilters, filter) => {
				if (filter[1].selectedValue !== "all") {
					return {
						...newFilters,
						[filter[1].key]: filter[1].selectedValue,
					};
				} else {
					return newFilters;
				}
			}, {}),
			...Object.entries(this.state.sorters).reduce((newSorters, sorter) => {
				return {
					...newSorters,
					[sorter[1].key]: sorter[1].selectedValue,
				};
			}, {}),
			...this.state.search.searchParams,
		};

		const paramsUrl = Object.entries(params)
			.map(([key, value]) => `${key}=${value}`)
			.join("&");

		const searchString = `?${paramsUrl}&searchTitle=${this.state.search.title}`;
		const paramsId = encodeURIComponent(searchString);

		const parseData = data => {
			const perPage = parseInt(data.per_page, 10);
			const totalResults = parseInt(data.total_count, 10);
			//TODO: Reduce contributions object
			const contributions = data.data;
			Cache.setData({
				id: "contributions__" + paramsId,
				data: data,
				stateOnly: true,
			});
			this.props.history.push(searchString);

			this.setState(
				{
					isLoading: false,
					currentPage: parseInt(data.page, 10),
					perPage,
					totalResults,
					totalPages: Math.ceil(totalResults / perPage),
					contributions,
				},
				() => {
					if (typeof cb === "function") {
						cb(contributions);
					}
				}
			);
		};

		if (Cache.getData("contributions__" + paramsId)) {
			return parseData(Cache.getData("contributions__" + paramsId).data);
		}

		return api.getContributions(params).then(parseData);
	}

	fetchContributions() {
		this.setState(
			{ isLoading: true, contributions: [], selectedContributions: [] },
			() => {
				this.getContributions();
			}
		);
	}

	setFilter(filterId, selectedValue) {
		const filters = Object.keys(this.state.filters).reduce((newFilters, id) => {
			if (id !== filterId) {
				return { ...newFilters, [id]: this.state.filters[id] };
			} else {
				return {
					...newFilters,
					[id]: { ...this.state.filters[id], selectedValue },
				};
			}
		}, {});

		this.setState({ filters, currentPage: 1 }, this.fetchContributions);
	}

	setSorter(sortId, selectedValue) {
		const sorters = Object.keys(this.state.sorters).reduce((newSorters, id) => {
			if (id !== sortId) {
				return { ...newSorters, [id]: this.state.sorters[id] };
			} else {
				return {
					...newSorters,
					[id]: { ...this.state.sorters[id], selectedValue },
				};
			}
		}, {});

		this.setState({ sorters, currentPage: 1 }, this.fetchContributions);
	}

	setSearch(selectedValue) {
		this.setState(
			{ search: selectedValue, currentPage: 1 },
			this.fetchContributions
		);
	}

	prevPage() {
		const currentPage = this.state.currentPage - 1;

		this.setState({ currentPage }, this.fetchContributions);
	}

	nextPage() {
		const currentPage = this.state.currentPage + 1;

		this.setState({ currentPage }, this.fetchContributions);
	}

	selectContribution(slug, selectMode) {
		const isSelected = this.state.selectedContributions.indexOf(slug) > -1;

		switch (selectMode) {
			case "single": {
				this.setState({ selectedContributions: isSelected ? [] : [slug] });
				break;
			}
			case "single-multiple": {
				let selectedContributions = [];

				if (isSelected) {
					selectedContributions = this.state.selectedContributions.filter(
						c => c !== slug
					);
				} else {
					selectedContributions = [slug, ...this.state.selectedContributions];
				}

				this.setState({ selectedContributions });
				break;
			}
			case "multiple": {
				const firstIndex = this.state.contributions.findIndex(
					c => c.slug === this.state.selectedContributions[0]
				);
				const lastIndex = this.state.contributions.findIndex(
					c => c.slug === slug
				);

				const multipleSelectedContributions = this.state.contributions
					.slice(
						firstIndex < lastIndex ? firstIndex : lastIndex,
						lastIndex > firstIndex ? lastIndex + 1 : firstIndex
					)
					.map(c => c.slug);

				const selectedContributions = [
					...multipleSelectedContributions,
					...this.state.selectedContributions.filter(
						c => !multipleSelectedContributions.includes(c.slug)
					),
				];

				this.setState({ selectedContributions });
				break;
			}
			default:
		}
	}

	changeTofullscreenMode(slug, force) {
		if (
			!force &&
			(this.state.selectedContributions.length !== 1 ||
				slug !== this.state.selectedContributions[0])
		) {
			return false;
		}

		const urlParams = new URLSearchParams(this.props.location.search);
		this.props.history.push(
			`/curation/${slug}?${urlParams.toString()}`,
			this.state.contributions
		);

		return false;
	}

	updateAllContributions() {
		const ids = this.state.contributions.map(c => c.django_id);
		const actions = [
			"explicitly_level__safe",
			"model_release__not_needed",
			"status__publish",
		];

		return this.updateContributions(ids, actions);
	}

	updateContributions(ids, actions, data = "") {
		const updateParams = {
			ids: ids.join(),
			actions_to_update: actions.join(),
			...data,
		};

		return api.bulkUpdateContributions(updateParams).then(() => {
			this.getContributions();
			//TODO: Update contributions state
			//TODO: Show snackbar
		});
	}

	updateContribution(slug, params, callback) {
		return api.updateContribution(slug, params).then(updatedContribution => {
			const contributions = this.state.contributions.map(c => {
				if (~~c.django_id === ~~updatedContribution.id) {
					c.categories = updatedContribution.category;
					c.tags_admin_high = updatedContribution.tags_admin_high;
					c.tags = updatedContribution.tags;
					c.tags_machine = updatedContribution.tags_machine;
				}

				return c;
			});

			this.setState({ contributions });

			if (typeof callback === "function") {
				callback();
			}
		});
	}

	async handleImageTypeChange(contributions, value) {
		this.setState({
			updatingImageType: true,
		});
		value = "image_type__" + value;
		let ids = [];

		contributions.forEach(c => {
			ids.push(c.django_id);
		});

		this.updateContributions(ids, [value]).then(() => {
			this.getContributions();
			this.setState({
				updatingImageType: false,
			});
		});
	}

	updateContributionCategories(contributionIds, categoriesIds) {
		let rules = [];

		if (this.state.filters["filter-by-category"].selectedValue === "all") {
			categoriesIds.forEach(c => {
				if (c) {
					rules.push({
						action: "add",
						category_id: c,
					});
				}
			});
		} else {
			const selectedCategoryObject = this.props.categories.find(
				c => c.slug === this.state.filters["filter-by-category"].selectedValue
			);

			let hasCurrentCategory =
				categoriesIds.indexOf(selectedCategoryObject.id) !== -1;

			if (hasCurrentCategory) {
				categoriesIds = categoriesIds.filter(
					c => c !== selectedCategoryObject.id
				);
			}

			rules = categoriesIds.map(c => ({
				action: "add",
				category_id: c,
			}));

			if (!hasCurrentCategory) {
				rules.push({
					action: "remove",
					category_id: selectedCategoryObject.id,
				});
			}
		}

		return api
			.bulkUpdateContributionCategories({
				contribution_ids: contributionIds,
				rules: rules,
			})
			.then(() => {
				this.fetchContributions();
			});
	}

	updateContributionKeywords = (type, resultTags, callback) => {
		const selectedContributionsObjects = this.state.contributions.filter(c =>
			this.state.selectedContributions.includes(c.slug)
		);

		let ids = [];
		let actions = [];
		selectedContributionsObjects.forEach((contribution, index) => {
			ids.push(contribution.django_id);
		});

		if (type === "delete") {
			actions = [
				"tags__remove_machine",
				"tags__remove_human",
				"tags__remove_admin",
			];
		} else {
			actions = ["tags__add_admin"];
		}
		this.updateContributions(ids, actions, {
			tags: resultTags.join(),
		}).then(() => {
			setTimeout(() => {
				callback();
				this.getContributions();
			}, 2000);
		});
	};

	addContributionsToCollection(selectedCollection) {
		const userId = authentication.getUser().id;

		return api.addToCollection(
			userId,
			selectedCollection,
			this.state.selectedContributions
		);
	}

	downloadContributions() {
		const selectedContributionsObjects = this.state.contributions.filter(c =>
			this.state.selectedContributions.includes(c.slug)
		);

		selectedContributionsObjects.forEach(c =>
			window.open(c.photos.find(p => p.name === "source").url)
		);
	}

	render() {
		const {
			isLoading,
			currentPage,
			perPage,
			totalPages,
			contributions,
			selectedContributions,
			filters,
			sorters,
			search,
			updatingImageType,
		} = this.state;

		const { categories } = this.props;

		const {
			setFilter,
			setSorter,
			setSearch,
			prevPage,
			nextPage,
			selectContribution,
			changeTofullscreenMode,
			updateContribution,
			updateContributionCategories,
			updateContributionKeywords,
			addContributionsToCollection,
			downloadContributions,
		} = this;

		const selectedContributionsObjects = contributions.filter(c =>
			selectedContributions.includes(c.slug)
		);

		const selectedContributionsIds = selectedContributionsObjects.map(
			c => c.django_id
		);
		let selectedCategoryObject = [];

		if (filters["filter-by-category"].selectedValue !== "all") {
			selectedCategoryObject = categories.find(
				c => c.slug === filters["filter-by-category"].selectedValue
			);
		}

		return (
			<div>
				<Easter />
				<ContainerWithRightSidebar
					stickyOffset={120}
					content={
						<div>
							<ToolbarToFilterAndSort
								filters={filters}
								setFilter={setFilter}
								sorters={sorters}
								setSorter={setSorter}
								search={search}
								setSearch={setSearch}
							/>

							{isLoading ? (
								<div className="loader">
									<CircularProgress />
								</div>
							) : (
								<ContributionsGrid
									contributions={contributions}
									selectedContributions={selectedContributions}
									selectContribution={selectContribution}
									changeTofullscreenMode={changeTofullscreenMode}
								/>
							)}

							{totalPages !== null ? (
								<Pagination
									isDisabled={isLoading}
									currentPage={currentPage}
									perPage={perPage}
									totalPages={totalPages}
									goBack={prevPage}
									goForward={nextPage}
								/>
							) : null}
						</div>
					}
					sidebar={
						<div>
							<List>
								<div className="list-selects" style={{ padding: "20px 0" }}>
									<FormControl style={{ width: "100%" }}>
										<InputLabel>Image type</InputLabel>
										<Select
											style={{ width: "100%" }}
											disabled={updatingImageType}
											onChange={(event, key) =>
												this.handleImageTypeChange(
													selectedContributionsObjects,
													event.target.value
												)
											}
											value={
												selectedContributions.length > 1
													? ""
													: (selectedContributionsObjects[0] &&
															selectedContributionsObjects[0].image_type) ||
													  ""
											}>
											<MenuItem value={"photo"}>Photo</MenuItem>
											<MenuItem value={"illustration"}>Illustration</MenuItem>
										</Select>
										{updatingImageType ? (
											<LinearProgress color="primary" />
										) : (
											""
										)}
									</FormControl>
								</div>
							</List>

							<div className="buttons-container" style={{ marginTop: 0 }}>
								<AddToCollection
									addContributionsToCollection={addContributionsToCollection}
								/>
								<IconButton
									onClick={downloadContributions}
									className="download-button">
									<DownloadIcon />
								</IconButton>
							</div>

							{selectedContributions.length === 1 ? (
								<div>
									<ContributionDetails
										groups={ContributionDarwin.curation}
										darwinKey={"curation"}
										categories={categories}
										contribution={selectedContributionsObjects[0]}
										updateContribution={updateContribution}
										setSearch={setSearch}
									/>
									<Divider className="add-to-collection-sep" />
								</div>
							) : null}

							{selectedContributions.length > 1 ? (
								<div>
									<ContributionCategoriesActions
										buttonLabel={
											filters["filter-by-category"].selectedValue !== "all"
												? "Change categories"
												: "Add category"
										}
										title={"Categories"}
										contributionIds={selectedContributionsIds}
										items={categories}
										selectedItems={[selectedCategoryObject]}
										updateContribution={updateContributionCategories}
									/>
									<ContributionKeywordsActions
										buttonLabel={"Delete keywords"}
										title={"Delete keywords"}
										type={"delete"}
										updateKey={"tags"}
										selectedItems={selectedContributionsObjects}
										updateContribution={updateContributionKeywords}
									/>
									<ContributionKeywordsActions
										buttonLabel={"Add keywords"}
										title={"Add keywords"}
										type={"add"}
										updateKey={"tags"}
										selectedItems={selectedContributionsObjects}
										updateContribution={updateContributionKeywords}
									/>
								</div>
							) : null}
						</div>
					}
					showSidebar={selectedContributions.length > 0}
				/>
			</div>
		);
	}
}

export default Curation;
