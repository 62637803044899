import React, { Component } from "react";

import {
	List,
	ListItem,
	ListItemText,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContent,
	TextField,
	CircularProgress,
	Checkbox,
	LinearProgress,
	Divider,
	ListSubheader,
} from "@material-ui/core";

import Mousetrap from "mousetrap";

import authentication from "../authentication";
import api from "../api";

const SelectableList = wrapState(List);

function wrapState(ComposedComponent) {
	return class SelectableList extends Component {
		render() {
			const { title, listKey, items, onItemSelect, selected } = this.props;

			return (
				<ComposedComponent value={selected} onChange={onItemSelect}>
					<ListSubheader>{title}</ListSubheader>

					{items.length > 0
						? items.map((item, i) => (
								<ListItem
									style={{ padding: "0" }}
									dense
									key={i}
									onClick={event =>
										onItemSelect(event, `${listKey}:${item.slug}`)
									}
									button>
									<Checkbox
										checked={`${listKey}:${item.slug}` === selected}
										tabIndex={-1}
										disableRipple
										style={{ height: 42 }}
									/>
									<ListItemText>{item.title}</ListItemText>
								</ListItem>
						  ))
						: null}
				</ComposedComponent>
			);
		}
	};
}

class CreateCollectionDialog extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			isLoading: false,
			name: "",
		};

		this.toggleDialog = this.toggleDialog.bind(this);
		this.onCollectionNameChange = this.onCollectionNameChange.bind(this);
		this.createCollection = this.createCollection.bind(this);
	}

	toggleDialog() {
		if (!this.state.isLoading) {
			this.setState({ isOpen: !this.state.isOpen });
		}
	}

	onCollectionNameChange(e, name) {
		this.setState({ name: e.target.value });
	}

	createCollection(event) {
		if (event) {
			event.preventDefault();
		}
		this.setState({
			isLoading: true,
		});
		api.createUserCollection(this.state.name).then(result => {
			this.setState({
				isLoading: false,
				isOpen: false,
			});
			if (this.props.callback && typeof this.props.callback === "function") {
				this.props.callback(result.collection);
			}
		});
	}

	render() {
		const { isOpen, isLoading, name } = this.state;
		const { toggleDialog, onCollectionNameChange, createCollection } = this;

		let progressBar = <LinearProgress mode="indeterminate" />;

		if (!isLoading) {
			progressBar = "";
		}

		return (
			<div>
				<Button
					onClick={toggleDialog}
					color="primary"
					variant="contained"
					fullWidth>
					Create Collection
				</Button>

				<Dialog modal={false} open={isOpen} onRequestClose={toggleDialog}>
					<DialogTitle>Create collection</DialogTitle>
					<DialogContent>
						<form onSubmit={createCollection}>
							<TextField
								label="New collection name"
								fullWidth
								autofocus
								value={name}
								onChange={onCollectionNameChange}
							/>
							{progressBar}
						</form>
					</DialogContent>
					<DialogActions>
						<Button label="Cancel" onClick={toggleDialog}>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="contained"
							disabled={isLoading || name.length === 0}
							onClick={createCollection}>
							Create Collection
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

class AddToCollection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			recentCollections:
				JSON.parse(localStorage.getItem("recentCollections")) || [],
			pickitCollections: [],
			userCollections: [],
			selectedCollection: null,
			isLoading: false,
			isOpen: false,
			searchQuery: "",
		};

		this.addRecentCollection = this.addRecentCollection.bind(this);
		this.selectCollection = this.selectCollection.bind(this);
		this.toggleDialog = this.toggleDialog.bind(this);
		this.addContributionsToCollection = this.addContributionsToCollection.bind(
			this
		);
		this.filterCollections = this.filterCollections.bind(this);
	}

	componentDidMount() {
		if (this.props.bindKey) {
			Mousetrap.bind([this.props.bindKey], this.toggleDialog);
		}
		this.refreshCollections();
	}

	componentWillUnmount() {
		if (this.props.bindKey) {
			Mousetrap.unbind([this.props.bindKey]);
		}
	}

	refreshCollections = async () => {
		let pickitCollections = await api.getPickitCollections();
		let userCollections = await api.getAllUserCollections(
			authentication.getUser().id
		);
		this.setState({
			pickitCollections: pickitCollections,
			userCollections: userCollections,
		});
		return true;
	};

	addRecentCollection(collection) {
		let recentCollections = [...this.state.recentCollections];

		const duplicateIndex = recentCollections.findIndex(
			c => c.id === collection.id
		);

		if (duplicateIndex > -1) {
			recentCollections.splice(
				0,
				0,
				recentCollections.splice(duplicateIndex, 1)[0]
			);
		} else {
			if (recentCollections.length >= 3) {
				recentCollections.pop();
			}

			recentCollections.unshift(collection);
		}

		localStorage.setItem(
			"recentCollections",
			JSON.stringify(recentCollections)
		);
		this.setState({ recentCollections });
	}

	selectCollection(event, item) {
		this.setState({ selectedCollection: item });
	}

	toggleDialog() {
		if (!this.state.isLoading) {
			this.setState({ isOpen: !this.state.isOpen });
		}
	}

	addContributionsToCollection() {
		this.setState({ isLoading: true });
		const [
			collectionKey,
			collectionValue,
		] = this.state.selectedCollection.split(":");
		const collection = this.state[collectionKey].find(
			c => c.slug === collectionValue
		);

		this.addRecentCollection(collection);

		this.props
			.addContributionsToCollection(collection.slug)
			.then(() => this.setState({ isLoading: false, isOpen: false }));
	}

	createCollection() {
		// this.addRecentCollection(collection)
	}

	filterCollections(e, val) {
		this.setState({ searchQuery: e.target.value });
	}

	collectionCreated = async collection => {
		this.addRecentCollection(collection);
		this.setState({
			isLoading: true,
		});
		await this.refreshCollections();
		this.setState({
			isLoading: false,
		});
	};

	render() {
		const {
			recentCollections,
			pickitCollections,
			userCollections,
			selectedCollection,
			isLoading,
			isOpen,
			searchQuery,
		} = this.state;
		const {
			collectionCreated,
			selectCollection,
			toggleDialog,
			addContributionsToCollection,
			filterCollections,
		} = this;

		const filteredRecentCollections = recentCollections.filter(
			c => c.title.toLowerCase().indexOf(searchQuery) > -1
		);
		const filteredPickitCollections = pickitCollections.filter(
			c => c.title.toLowerCase().indexOf(searchQuery) > -1
		);
		const filteredYourCollections = userCollections.filter(
			c => c.title.toLowerCase().indexOf(searchQuery) > -1
		);

		return (
			<div>
				<Button
					color="primary"
					variant="contained"
					onClick={toggleDialog}
					fullWidth>
					Add to collection
				</Button>

				<Dialog modal={false} open={isOpen} onRequestClose={toggleDialog}>
					<DialogTitle>Add to collection</DialogTitle>
					<DialogContent style={{ width: 600 }}>
						<CreateCollectionDialog callback={collectionCreated} />

						{!isLoading ? (
							<div>
								<div style={{ padding: "25px 0 0 0" }}>
									<TextField
										hintText="Search for collection"
										label="Search for collection"
										fullWidth
										value={searchQuery}
										onChange={filterCollections}
									/>
								</div>

								<SelectableList
									title="Recent collections"
									listKey="recentCollections"
									items={filteredRecentCollections}
									onItemSelect={selectCollection}
									selected={selectedCollection}
								/>
								<Divider />

								<SelectableList
									title="Your collections"
									listKey="userCollections"
									items={filteredYourCollections}
									onItemSelect={selectCollection}
									selected={selectedCollection}
								/>
								<Divider />

								<SelectableList
									title="User collections"
									listKey="pickitCollections"
									items={filteredPickitCollections}
									onItemSelect={selectCollection}
									selected={selectedCollection}
								/>
							</div>
						) : (
							<CircularProgress />
						)}
					</DialogContent>
					<DialogActions>
						<Button label="Cancel" onClick={toggleDialog}>
							Cancel
						</Button>
						<Button
							label="Add to collection"
							color="primary"
							variant="contained"
							disabled={isLoading || !selectedCollection}
							onClick={addContributionsToCollection}>
							Add to collection
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
}

export default AddToCollection;
