import React, { Component } from 'react'

class ContainerWithRightSidebar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSticky: false
    }

    this.handleScroll = this.handleScroll.bind(this)
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(e) {
    if (window.pageYOffset >= this.props.stickyOffset) {
      this.setState({ isSticky: true })
    } else {
      this.setState({ isSticky: false })
    }
  }

  render() {
    const { isSticky } = this.state
    const { content, sidebar, showSidebar } = this.props

    return (
      <div className={`grid-list-container ${showSidebar ? 'collapsed' : ''}`}>
        <div className="grid-list-content-wrapper">
          {content}
        </div>
  
        <div className={`contribution-details-pane ${showSidebar ? 'collapsed' : ''} ${isSticky && 'sticky'}`}>
          {sidebar}
        </div>
      </div>
    )
  }
} 

export default ContainerWithRightSidebar
