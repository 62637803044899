
    import React, { Component } from "react";
    import api from "../api";
    import { LinearProgress, Button } from "@material-ui/core"


    class GettyImages extends Component {
        constructor(props){
            super(props);
            this.state = {
                query: "",
                results: [],
                page: 1
            }
        }
        search = async (query, clear) => {
            let stateData = {
                loading: true
            }
            if(clear){
                stateData.results = []
                stateData.page = 1
            }
            let data = await api.searchGettyImages(query, this.state.page);
            this.setState({
                results: data.images,
                loading: false,
                ...stateData
            })
        }
        onSearchChange = (event) => {
            this.setState({
                query: event.target.value
            });
        }
        onSearchKeypress = (event) => {
            console.log(event.key);
            if(event.key === "Enter"){
                this.search(this.state.query, true);
            }
        }
        previousPage = () => {
            let newNumber = this.state.page - 1;
            this.setState({
                page: newNumber,
                results: []
            }, () => {
                window.scrollTo(0,0);
                this.search(this.state.query);
            })
        }
        nextPage = () => {
            let newNumber = this.state.page + 1;
            this.setState({
                page: newNumber,
                results: []
            }, () => {
                window.scrollTo(0,0);
                this.search(this.state.query);
            })
        }
        render(){
          let delay = 0;
          let images = this.state.results.map(image => {
            delay += 0.02;
            return (
             <div className="result">
                <div className="wrapper">
                    <div className="image" style={{ backgroundImage: "url("+image.display_sizes[0].uri+")", animationDelay: delay + "s" }}></div>
                </div>
             </div>
            )
          })
          return (
            <div className="getty-images">
               <div className="search">
                 <input type="text" placeholder="Search Getty Images" value={this.state.query} onKeyPress={ (event) => this.onSearchKeypress(event) } onChange={this.onSearchChange} autoFocus={true} />
                 { this.state.loading ? <LinearProgress mode="indeterminate" /> : "" }
               </div>
               <div className="results">
                 { images }
               </div>
               <div className="pagination">
                <Button variant="contained" color="primary" disabled={ this.state.page === 1 || !this.state.results.length } onClick={ () => this.prevPage() }>
                    Previous page
                </Button>
                <Button variant="contained" color="primary" disabled={ !this.state.results.length } onClick={ () => this.nextPage() }>
                    Next page
                </Button>
               </div>
            </div>
          )
        }
    }



    export default GettyImages;