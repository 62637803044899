import React, { Component } from "react";

import {
	Button,
	TextField,
	LinearProgress,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	FormGroup,
	FormControl,
	FormHelperText,
} from "@material-ui/core";

import api from "../api";
import Auth from "../authentication";

class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			username: "",
			password: "",
			isLoading: false,
		};

		this.onUsernameChange = this.onUsernameChange.bind(this);
		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onKeypress = this.onKeypress.bind(this);
		this.onLogin = this.onLogin.bind(this);
	}

	onUsernameChange(e, username) {
		this.setState({
			username: e.target.value,
			error: false,
		});
	}

	onPasswordChange(e, password) {
		this.setState({
			password: e.target.value,
			error: false,
		});
	}

	onLogin() {
		this.setState({
			isLoading: true,
		});
		api
			.getToken(this.state.username, this.state.password)
			.then(user => {
				if (user && user.token) {
					Auth.authenticate(user);
					api.profile().then(data => {
						if (!data.is_staff) {
							this.setState({
								password: "",
								isLoading: false,
								error: "Account is not staff.",
							});
							return false;
						}
						this.setState({
							isLoading: false,
						});
						this.props.setAuthenticated(true);
					});
				} else {
					this.setState({
						password: "",
						isLoading: false,
						error: "Email or password is invalid. Please try again",
					});
				}
			})
			.catch(e => {
				this.setState({
					isLoading: false,
					error: "Email or password is invalid. Please try again",
				});
			});
	}

	onKeypress(e) {
		if (e.key === "Enter") {
			this.onLogin();
		}
	}

	render() {
		const { username, password, isLoading } = this.state;

		return (
			<div className="login">
				<div className="wrapper">
					<div className="outside"></div>
					<Card style={{ padding: 20 }}>
						<CardHeader
							title="Sign in to the CMP"
							style={{ paddingBottom: 0 }}
						/>
						<CardContent style={{ paddingTop: 0 }}>
							<form onKeyPress={this.onKeypress}>
								<FormGroup style={{ margin: 0, padding: 0 }}>
									<FormControl>
										<TextField
											label="Enter your email"
											fullWidth
											autoFocus
											value={username}
											onChange={this.onUsernameChange}
											margin="normal"
										/>
										{this.state.error ? (
											<FormHelperText>{this.state.error}</FormHelperText>
										) : (
											""
										)}
									</FormControl>
								</FormGroup>
								<FormGroup style={{ margin: 0, padding: 0 }}>
									<FormControl>
										<TextField
											label="Enter your password"
											fullWidth
											type="password"
											value={password}
											margin="normal"
											onChange={this.onPasswordChange}
										/>
										{this.state.error ? (
											<FormHelperText>{this.state.error}</FormHelperText>
										) : (
											""
										)}
									</FormControl>
								</FormGroup>
							</form>
						</CardContent>
						<CardActions style={{ justifyContent: "flex-end" }}>
							<Button
								label="Login"
								color="primary"
								variant="contained"
								onClick={this.onLogin}>
								Login
							</Button>
						</CardActions>
					</Card>
					<div className="outside">
						{process.env.REACT_APP_ENVIRONMENT === "dev" ||
						process.env.REACT_APP_ENVIRONMENT === "stage"
							? process.env.REACT_APP_ENVIRONMENT
							: ""}
						{isLoading ? (
							<LinearProgress mode="indeterminate" />
						) : (
							<LinearProgress mode="indeterminate" style={{ opacity: 0 }} />
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
