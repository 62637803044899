import authentication from "./authentication";

const baseUrl = `${process.env.REACT_APP_API_URL}`;
// const baseUrl = 'https://pickit.com';

function getHeaders(contentType) {
	const authHeaders = new Headers();

	if (authentication.isAuthenticated()) {
		authHeaders.append("Simpletoken-Auth", authentication.getUser().token);
	}

	if (contentType) {
		authHeaders.append("Content-Type", contentType);
	}

	return authHeaders;
}

class api {
	static getToken(username, password) {
		const url = new URL(`${baseUrl}/api/token/create/`);

		return fetch(url, {
			method: "POST",
			headers: getHeaders("application/x-www-form-urlencoded charset=utf-8"),
			body: `username=${username}&password=${password}&keep_me_logged_in=true`,
		})
			.then(response => response.json())
			.catch(error => error);
	}

	static async getCategories() {
		let url = new URL(
			`${baseUrl}/api/categories/?page_limit=100&filter_type=contribution`
		);

		let response = await fetch(url, {
			method: "GET",
			headers: getHeaders(),
		});
		let data = await response.json();
		data = data.filter(category => {
			var found = category.apps.find(app => {
				return app === "pichitme";
			});
			return typeof found !== "undefined";
		});
		return data;
	}

	static async getContributors(query) {
		const url = new URL(
			`${baseUrl}/api/search/autocomplete/?query=${query}&return_type=contribution`
		);
		let response = await fetch(url, {
			method: "GET",
			headers: getHeaders(),
		});
		return await response.json();
	}

	static getContributions(params) {
		let url = new URL(`${baseUrl}/api/julietta/search/contributions/`);
		Object.keys(params).forEach(key =>
			url.searchParams.append(key, params[key])
		);

		return fetch(url, {
			method: "GET",
			headers: getHeaders(),
		}).then(response => response.json());
	}

	static getContributionDownloadLinks(slug) {
		const url = new URL(`${baseUrl}/api/contributions/${slug}/download_links/`);

		return fetch(url, {
			method: "GET",
			headers: getHeaders(),
		}).then(response => response.json());
	}

	static rateContribution(slug, rating) {
		const url = new URL(`${baseUrl}/api/contributions/${slug}/rate/`);

		return fetch(url, {
			method: "POST",
			headers: getHeaders("application/x-www-form-urlencoded charset=utf-8"),
			body: `rating=${rating}`,
		}).then(response => response.json());
	}

	static updateContribution(slug, updateParams) {
		const url = new URL(`${baseUrl}/api/contributions/${slug}/update`);

		return fetch(`${url}`, {
			method: "POST",
			headers: getHeaders("application/json"),
			body: JSON.stringify(updateParams),
		}).then(response => response.json());
	}

	static bulkUpdateContributions(updateParams) {
		const url = new URL(`${baseUrl}/api/julietta/search/update/`);
		const params = Object.entries(updateParams)
			.map(([key, value]) => `${key}=${value}`)
			.join("&");

		return fetch(url, {
			method: "PUT",
			headers: getHeaders("application/x-www-form-urlencoded charset=utf-8"),
			body: params,
		}).then(response => response.json());
	}

	static bulkUpdateContributionCategories(updateParams) {
		const url = new URL(`${baseUrl}/api/categories/contribution-actions/`);

		return fetch(url, {
			method: "POST",
			headers: getHeaders("application/json"),
			body: JSON.stringify(updateParams),
		}).then(response => response.json());
	}

	static getPickitCollections() {
		// const webCollections = fetch(`${baseUrl}/api/collections/explore/source/web/?collection_nbr=200`, {
		//   method: 'GET',
		//   headers: getHeaders()
		// })
		// .then(response => response.json())

		// const uwpCollections = fetch(`${baseUrl}/api/collections/explore/source/uwp/?collection_nbr=200`, {
		//   method: 'GET',
		//   headers: getHeaders()
		// })
		// .then(response => response.json())

		const addinCollections = fetch(
			`${baseUrl}/api/collections/explore/source/office_addin/?collection_nbr=200`,
			{
				method: "GET",
				headers: getHeaders(),
			}
		).then(response => response.json());

		return Promise.all([
			/* webCollections, uwpCollections, */ addinCollections,
		]).then(responses => {
			let collections = [];

			responses.forEach(response => {
				collections = collections
					.concat(response.featured_collections)
					.concat(response.hero_collections)
					.concat(response.promoted_collections);
			});

			collections = collections
				.filter(
					(collection, position) =>
						collections.findIndex(c => c.id === collection.id) === position
				)
				.sort((c1, c2) =>
					c1.title < c2.title ? -1 : c1.title > c2.title ? 1 : 0
				);

			return collections;
		});
	}

	static async getAllUserCollections(userId) {
		let collections = await fetch(`${baseUrl}/api/search/collections`, {
			method: "POST",
			headers: getHeaders(),
			body: JSON.stringify({
				fields:
					"slug,featured,name,approvals,pepito,shared,channels,background_photo,user,created_on,title,public,status,promoted,featured,hero,collection_tags,collection_categories,collection_image_type",
				page_limit: 5000,
				owner_id: userId,
				public: 0,
			}),
		});
		if (collections.ok) {
			collections = await collections.json();
		} else {
			throw collections;
		}
		collections = collections.data;
		collections = collections
			.filter(
				(collection, position) =>
					collections.findIndex(c => c.id === collection.id) === position
			)
			.sort((c1, c2) =>
				c1.title < c2.title ? -1 : c1.title > c2.title ? 1 : 0
			);

		return collections;
	}

	static addToCollection(userId, collectionSlug, images) {
		let promises = [];

		images.forEach(slug => {
			const url = `${baseUrl}/users/${userId}/collection/${collectionSlug}/add/photo`;

			promises.push(
				fetch(url, {
					method: "POST",
					headers: getHeaders(
						"application/x-www-form-urlencoded charset=utf-8"
					),
					body: `contribution_slug=${slug}`,
				})
			);
		});

		return Promise.all(promises);
	}

	static removeFromCollection(userId, collectionSlug, images) {
		let data = {
			contributions: [],
		};
		const url = `${baseUrl}/api/users/${userId}/collection/${collectionSlug}/remove/photo`;

		images.forEach(image => {
			if (typeof image === "object") {
				image = image.slug;
			}
			data.contributions.push({
				contribution_slug: image,
			});
		});

		data = JSON.stringify(data);
		return fetch(url, {
			method: "POST",
			headers: getHeaders("application/x-www-form-urlencoded charset=utf-8"),
			body: data,
		});
	}

	static createUserCollection(title) {
		const url = new URL(`${baseUrl}/collection/create/`);

		return fetch(url, {
			method: "POST",
			headers: getHeaders("application/x-www-form-urlencoded charset=utf-8"),
			body: `title=${title}`,
		}).then(response => response.json());
	}

	static profile() {
		return fetch(`${baseUrl}/api/profile/`, {
			method: "GET",
			headers: getHeaders(),
		}).then(response => response.json());
	}

	static async searchGettyImages(query, page) {
		let response = await fetch(
			"https://api.gettyimages.com/v3/search/images?phrase=" +
				query +
				"&page_size=25&page=" +
				(page || 1),
			{
				headers: {
					"Api-Key": "3dey25q8t8z5jjqnb48ndf6j",
				},
			}
		);
		let data = await response.json();
		return data;
	}
	static async modifyCollection(slug, data) {
		const url = new URL(`${baseUrl}/api/collection/${slug}/modify/`);
		try {
			let response = await fetch(url, {
				method: "POST",
				headers: getHeaders(),
				body: JSON.stringify(data),
			});
			let json = response.json();
			return json;
		} catch (e) {
			console.log(e);
			return false;
		}
	}
}

export default api;
