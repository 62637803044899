import React, { Component } from 'react'

import { 
  Button,
  Dialog,
  DialogActions, 
  DialogTitle, 
} from '@material-ui/core'

export default class RemoveFromCollection extends Component {

  constructor(props){
    super(props);
    this.state = {
      open: props.open
    }
    this.onSubmitFunction = props.onSubmit;
    this.onCancelFunction = props.onCancel;
  }

  handleClose = () => {
    this.onSubmitFunction();
    this.setState({ open: false });
  };

  render() {

    return (
      <div>
        <Dialog
          modal={false}
          open={this.props.open || false}
          onRequestClose={this.handleClose}
        >
          <DialogTitle>Are you sure you want to remove the contributions from the collection?</DialogTitle>
          <DialogActions>
            <Button onClick={this.onCancelFunction}>Cancel</Button>
            <Button
              color="secondary"
              variant="contained"
              keyboardFocused={true}
              onClick={this.handleClose}
            >Remove selected contributions</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
