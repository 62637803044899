const authentication = {
  authenticate(user) {
    Object.entries(user).forEach(([key, value]) => localStorage.setItem(key, value))
  },

  deauthenticate() {
    localStorage.clear()
  },

  isAuthenticated() {
    return !!localStorage.getItem('token')
  },

  getUser() {
    let user = {
      id: localStorage.getItem('userid'),
      token: localStorage.getItem('token'),
      username: localStorage.getItem('username')
    }

    return user
  }
}

export default authentication
