

const ContributionDarwin = {
  moderation: [
    {
      name: "User",
      items: ["user", "url"],
      open: false
    },
    {
      name: "File info",
      open: false,
      items: ["file_name", "size", "upload_date", "likes", "downloads"]
    },
    {
      name: "Manage",
      open: false,
      items: ["categories", /*"human_keywords", "admin_keywords", "machine_keywords",*/ "tags"]
    },
    {
      name: "Ratings",
      open: false,
      items: ["rating_users", "rating_admins"]
    }
  ],
  curation: [
    {
      name: "User",
      items: ["user", "url"]
    },
    {
      name: "File info",
      items: ["file_name", "size", "upload_date", "likes", "downloads"]
    },
    {
      name: "Manage",
      open: true,
      items: ["categories", /*"human_keywords", "admin_keywords", "machine_keywords",*/ "tags"]
    },
    {
      name: "Ratings",
      open: true,
      items: ["rating_users", "rating_admins"]
    }
  ],
  collections: [
    {
      name: "User",
      items: ["user", "url"]
    },
    {
      name: "File info",
      items: ["file_name", "size", "upload_date", "likes", "downloads"]
    },
    {
      name: "General",
      items: ["url"],
      open: false
    },
    {
      name: "Manage",
      open: false,
      items: ["categories", /*"human_keywords", "admin_keywords", "machine_keywords",*/ "tags"]
    },
    {
      name: "Ratings",
      open: true,
      items: ["rating_users", "rating_admins"]
    }
  ]
}

export default ContributionDarwin;