
    import clone from "clone";
    import moment from "moment";

    class CacheService {

        static cache = null

        static getCache(){
            let cache = localStorage.getItem("cache");
            if(!cache){
                cache = {};
            } else {
                cache = JSON.parse(cache);
            }
            this.cache = cache;
        }
        static save(){
            localStorage.setItem("cache", JSON.stringify(this.cache));
        }

        static set(key, data){
            if(!this.cache){
                this.getCache();
            }
            this.cache[key] = {
                updated: new Date(),
                data: data
            };
            this.save();
        }

        static get(key){
            if(!this.cache){
                this.getCache();
            }
            if(!this.cache[key]){
                return false;
            }
            let data = clone(this.cache[key]);
            data.moment = moment(data.updated);
            data.color = "#76d671";
            data.hourDiff = moment().diff(data.moment, "hours");
            if(data.hourDiff > 0){
                if(data.hourDiff > 3){
                    data.color = "#eb4d3d";
                } else {
                    data.color ="#f19a38";
                }
            }
            return data;
        }

    }

    export default CacheService;