import React, { Component } from "react";

import clone from "clone";

import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  CircularProgress,
  TextField,
  IconButton,
  FormControlLabel,
  Chip
} from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import EyeIcon from "@material-ui/icons/Visibility";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { HotKeys } from "react-hotkeys";

const keyMap = {
  update: ["enter"]
};

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

class EditDialogWithCheckboxes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isDialogOpen: false,
      selectedItems: this.props.selectedItems || []
    };

    this.toggleDialog = this.toggleDialog.bind(this);
    this.checkCategory = this.checkCategory.bind(this);
    this.update = this.update.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ selectedItems: nextProps.selectedItems });
  }

  toggleDialog() {
    if (this.state.isLoading) {
      return;
    }

    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  }

  checkCategory(category, isChecked) {
    if (isChecked) {
      this.setState({ selectedItems: [...this.state.selectedItems, category] });
    } else {
      this.setState({
        selectedItems: this.state.selectedItems.filter(
          c => c.slug !== category.slug
        )
      });
    }
  }

  update() {
    if (this.state.isLoading) {
      return;
    }

    let objectToUpdate = {
      name: this.props.contribution.name,
      category: (this.props.contribution.categories || []).map(c => c.slug),
      tags: (this.props.contribution.tags || []).map(c => c.name).join(","),
      tags_machine: (this.props.contribution.tags_machine || [])
        .map(c => c.slug)
        .join(",")
    };

    let itemsToUpdate = this.state.selectedItems.map(c => c.slug);
    if (this.props.updateType === "string") {
      itemsToUpdate = itemsToUpdate.join(",");
    }

    objectToUpdate[this.props.updateKey] = itemsToUpdate;

    this.setState({ isLoading: true });
    this.props
      .updateContribution(this.props.contribution.slug, objectToUpdate)
      .then(() => {
        this.setState({ isLoading: false, isDialogOpen: false });
      });
  }

  render() {
    const { isLoading, isDialogOpen, selectedItems } = this.state;
    const { items, title } = this.props;
    const { toggleDialog, update } = this;

    const handlers = {
      update: isDialogOpen && this.update
    };

    return (
      <HotKeys keyMap={keyMap} handlers={handlers}>
        <span className="edit-button">
          <IconButton onClick={toggleDialog}>
            <EditIcon />
          </IconButton>

          <Dialog
            open={isDialogOpen}
            onClose={toggleDialog}
            TransitionComponent={Transition}
          >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent
              style={{ width: 450, maxHeight: 500, display: "flex" }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "column"
                }}
              >
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  items.map((category, i) => (
                    <HotKeys
                      keyMap={keyMap}
                      handlers={handlers}
                      style={{ width: "50%" }}
                      key={i}
                    >
                      <FormControlLabel
                        style={{ padding: 0 }}
                        control={
                          <Checkbox
                            style={{ height: 32 }}
                            key={i}
                            onChange={(e, isChecked) =>
                              this.checkCategory(category, isChecked)
                            }
                            checked={
                              selectedItems &&
                              selectedItems.findIndex(
                                cat => cat.slug === category.slug
                              ) !== -1
                            }
                          />
                        }
                        label={category.name}
                      />
                    </HotKeys>
                  ))
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button label="Cancel" onClick={toggleDialog}>
                Cancel
              </Button>
              <Button
                label="Update"
                variant="contained"
                color="primary"
                onClick={update}
              >
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </span>
      </HotKeys>
    );
  }
}

class EditDialogWithTextArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isDialogOpen: false,
      newTag: "",
      tags: (() => {
        const tags = {};

        this.props.updateKey.forEach(key => {
          if (key === "tags") {
            tags[key] = (this.props.contribution[key] || [])
              .filter(
                tag => !this.props.contribution.tags_admin_high.includes(tag)
              )
              .map(tag => tag.name)
              .join(",");
          } else {
            tags[key] = (this.props.contribution[key] || [])
              .map(tag => tag.name)
              .join(",");
          }

          /*tags.tags = "human, human, human";
          tags.tags_admin_high = "admin, admin, admin";
          tags.tags_machine = "machine, machine, machine"*/
        });

        return tags;
      })(),
      highlightTags: this.props.highlightTags || ""
    };

    this.toggleDialog = this.toggleDialog.bind(this);
    this.onTextareaChange = this.onTextareaChange.bind(this);
    this.update = this.update.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps");
    this.setState({
      tags: (() => {
        const tags = {};

        this.props.updateKey.forEach(key => {
          tags[key] = (nextProps.contribution[key] || [])
            .map(tag => tag.name)
            .join(",");
        });

        return tags;
      })(),
      highlightTags: nextProps.highlightTags || ""
    });
  }

  toggleDialog() {
    if (this.state.isLoading) {
      return;
    }

    this.setState({ isDialogOpen: !this.state.isDialogOpen });
  }

  onTextareaChange(e) {
    this.setState({ tags: e.target.value });
  }

  async update() {
    if (this.state.isLoading) {
      return;
    }

    this.setState({ isLoading: true });
    if (Array.isArray(this.props.updateKey)) {
      await this.props.updateContribution(this.props.contribution.slug, {
        name: this.props.contribution.name,
        category: this.props.contribution.categories.map(c => c.slug),
        tags: this.state.tags.tags,
        tags_machine: this.state.tags.tags_machine,
        tags_admin_high: this.state.tags.tags_admin_high
      });
    } else {
      this.props.updateContribution(this.props.contribution.slug, {
        name: this.props.contribution.name,
        category: this.props.contribution.categories.map(c => c.slug),
        [this.props.updateKey]: this.state.tags.tags
          ? this.state.tags.tags + "," + this.state.newTag
          : this.state.newTag
      });
    }
    this.setState({ isLoading: false, isDialogOpen: false });
  }

  removeTag = (tag, type) => {
    let humanTags = this.state.tags.tags;
    let machineTags = this.state.tags.tags_machine;
    let adminTags = this.state.tags.tags_admin_high;
    let humanChips = humanTags.split(",");
    let machineChips = machineTags.split(",");
    let adminChips = adminTags.split(",");
    humanChips = humanChips.filter(chip => {
      return tag !== chip;
    });
    adminChips = adminChips.filter(chip => {
      return tag !== chip;
    });
    machineChips = machineChips.filter(chip => {
      return tag !== chip;
    });
    humanTags = humanChips.join(",");
    adminTags = adminChips.join(",");
    machineTags = machineChips.join(",");
    this.setState({
      tags: {
        tags: humanTags,
        tags_admin_high: adminTags,
        tags_machine: machineTags
      }
    });
  };

  changeTag = (event, tag) => {
    if (event.target.tagName !== "SPAN") {
      return false;
    }
    let val = window.prompt("Update tag", tag);
    if (val) {
      let tags = clone(this.state.tags);
      tags = tags.replace(tag, val);
      this.setState({ tags });
    }
  };

  commaToChips = type => {
    let tags = this.state.tags[type];

    let styles = {
      chip: {
        margin: 4
      },
      wrapper: {
        display: "flex",
        flexWrap: "wrap"
      }
    };

    let highlightTags = this.state.highlightTags.split(",");
    highlightTags = highlightTags.map(tag => tag.toLowerCase());

    tags = tags.split(",");
    tags = [...new Set(tags)];

    if (type === "tags") {
      tags = tags.filter(
        tag => !this.state.tags.tags_admin_high.split(",").includes(tag)
      );
    }

    let chips = tags.map((tag, key) => {
      let style = {
        margin: 4,
        opacity: 1
      };
      let color = "#000";
      let svgStyle = {
        fill: "black"
      };
      if (tag.indexOf(this.state.newTag) === -1) {
        style.opacity = 0.5;
      }
      if (type === "tags_machine") {
        style.backgroundColor = "#B2E2F2";
      }
      if (type === "tags") {
        style.backgroundColor = "#B5EEB0";
      }
      if (type === "tags_admin_high") {
        style.backgroundColor = "#FCBFE3";
      }
      if (tag.toLowerCase() === this.state.newTag.toLowerCase()) {
        style.boxShadow = "0px 0px 26px 4px rgba(119, 198, 245, 0.6)";
        style.backgroundColor = "rgba(51, 120, 246, 1.000)";
        color = "#FFFFFF";
        svgStyle.fill = "#FFFFFF";
        svgStyle.color = "#FFFFFF";
      }
      if (!tag) {
        return <div key={key}></div>;
      }
      return (
        <Chip
          onClick={event => this.changeTag(event, tag)}
          key={key}
          style={style}
          deleteIconStyle={svgStyle}
          labelColor={color}
          label={tag}
          onDelete={() => this.removeTag(tag, type)}
        />
      );
    });
    return <div style={styles.wrapper}>{chips}</div>;
  };

  newTagOnChange = e => {
    this.setState({ newTag: e.target.value });
  };

  tagOnChange = e => {
    this.setState({ tags: e.target.value });
  };

  handleNewTag = event => {
    if (event.keyCode === 13 && this.state.newTag) {
      let result = this.state.tags.tags_admin_high + "," + this.state.newTag;
      if (!this.state.tags.tags_admin_high) {
        result = this.state.newTag;
      }
      this.setState({
        tags: { ...this.state.tags, tags_admin_high: result },
        newTag: ""
      });
    }
  };

  render() {
    const { isLoading, isDialogOpen, tags, newTag } = this.state;
    const { title } = this.props;
    const { toggleDialog, update, handleNewTag, newTagOnChange } = this;

    const handlers = {
      update: isDialogOpen && this.update
    };

    let chips = this.props.updateKey.map(key => this.commaToChips(key));

    return (
      <HotKeys keyMap={keyMap} handlers={handlers}>
        <span className="edit-button">
          <IconButton onClick={toggleDialog}>
            <EditIcon />
          </IconButton>

          <Dialog
            open={isDialogOpen}
            onClose={toggleDialog}
            TransitionComponent={Transition}
          >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <div style={{ minWidth: "500px" }}>
                  {chips}
                  <TextField
                    fullWidth
                    style={{ marginTop: 15 }}
                    label={`Add new tag`}
                    onChange={newTagOnChange}
                    onKeyDown={handleNewTag}
                    value={newTag}
                  />
                  {/*<TextField multiline fullWidth style={{ marginTop: 20 }} label={'Tag row'} onChange={this.tagOnChange} value={tags} />*/}
                </div>
              )}
            </DialogContent>
            <DialogActions>
              {(() => {
                let existingTags = [
                  ...(this.props.contribution.tags.tags?.split(",") ?? []),
                  ...(this.props.contribution.tags.tags_machine?.split(",") ??
                    []),
                  ...(this.props.contribution.tags.tags_admin_high?.split(
                    ","
                  ) ?? [])
                ];
                existingTags = [...new Set(existingTags)];

                let autoTags = this.props.autoSuggestTags?.length
                  ? this.props.autoSuggestTags.map(tag => tag.name)
                  : [];
                autoTags = autoTags.filter(tag => !existingTags.includes(tag));

                if (!!autoTags.length) {
                  return (
                    <Button
                      style={{ marginRight: "auto" }}
                      onClick={() => {
                        let newTags = this.props.contribution.tags.tags_admin_high
                          ? this.props.contribution.tags.tags_admin_high?.split(",")
                          : [];
                        newTags = [...newTags, ...autoTags];
                        this.setState({
                          tags: {
                            ...this.state.tags,
                            tags_admin_high: newTags.join(",")
                          }
                        });
                      }}
                    >
                      Autofill metadata tags
                    </Button>
                  );
                } else {
                  return null;
                }
              })()}
              <Button onClick={toggleDialog}>Cancel</Button>
              <Button color="primary" onClick={update}>
                Update
              </Button>
            </DialogActions>
          </Dialog>
        </span>
      </HotKeys>
    );
  }
}

class ContributionDetails extends Component {
  constructor(props) {
    super(props);

    let groups = props.groups;
    let darwinHistory = JSON.parse(localStorage.getItem("darwinHistory"));
    if (darwinHistory && darwinHistory[this.props.darwinKey]) {
      darwinHistory = darwinHistory[this.props.darwinKey];
    }

    groups.forEach((group, key) => {
      if (darwinHistory && darwinHistory[key]) {
        groups[key].open = darwinHistory[key].open;
      }
    });

    this.state = {
      showCategoriesDialog: false,
      showAdminKeywordsDialog: false,
      showHumanKeywordsDialog: false,
      showMachineKeywordsDialog: false,
      groups: groups
    };

    this.toggleDialog = this.toggleDialog.bind(this);
  }

  componentWillReceiveProps(nextProps) {}

  toggleDialog(dialog) {
    this.setState({ [dialog]: !this.state[dialog] });
  }

  printTags(tags) {
    const { human: humanTags, machine: machineTags, admin: adminTags } = tags;

    let human = [...new Set(humanTags?.map(text => text.name) ?? [])];
    let machine = [...new Set(machineTags?.map(text => text.name) ?? [])];
    let admin = [...new Set(adminTags?.map(text => text.name) ?? [])];
    human = human.filter(tag => !admin.includes(tag));

    const humanText = human ? human.join(", ") : "";
    const machineText = machine ? machine.join(", ") : "";
    const adminText = admin ? admin.join(", ") : "";

    const hasTags =
      humanText.length > 1 || machineText.length > 1 || adminText.length > 1;

    let text;

    if (hasTags) {
      text = (
        <span>
          <span style={{ color: "#40AB76" }}>{humanText}</span>
          {humanText.length && (adminText.length || machineText.length)
            ? ", "
            : ""}
          <span style={{ color: "#4FA0BB" }}>{machineText}</span>
          {machineText.length ? ", " : ""}
          <span style={{ color: "#D266A6" }}>{adminText}</span>
        </span>
      );
    } else {
      text = "N/A";
    }

    return <span className="no-text-clamp">{text}</span>;
  }

  generateListItem(props) {
    const itemStyle = {
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 40
    };
    return (
      <ListItem key={props.key} style={itemStyle}>
        <ListItemText
          primary={props.primary || "NO PRIMARY TEXT"}
          secondary={props.secondary || ""}
        />
        {props.icon || ""}
      </ListItem>
    );
  }

  generateTemplate = () => {
    const {
      categories,
      contribution,
      updateContribution,
      setSearch
    } = this.props;
    const { generateListItem } = this;

    if (!contribution) {
      return false;
    }

    this.items = {
      user: generateListItem({
        key: "user",
        primary: "User",
        secondary:
          contribution && contribution.user && contribution.user.display_name,
        icon: (
          <IconButton color="secondary" aria-label="Search user">
            <EyeIcon
              style={{ opacity: 0.65, cursor: "pointer" }}
              onClick={() =>
                setSearch({
                  title: contribution.user.display_name,
                  searchParams: { by_user: contribution.user.id }
                })
              }
            />
          </IconButton>
        )
      }),
      url: generateListItem({
        key: "url",
        primary: "Url",
        secondary: (
          <a href={contribution.url || ""} target="_blank">
            Open contribution in a new tab
          </a>
        )
      }),
      upload_date: generateListItem({
        key: "upload_date",
        primary: "Upload date",
        secondary: contribution.created_on.substr(0, 10)
      }),
      size: generateListItem({
        key: "size",
        primary: "Size",
        secondary: contribution.size[0] + "x" + contribution.size[1]
      }),
      file_name: generateListItem({
        key: "file_name",
        primary: "Name",
        secondary: contribution.name || "N/A"
      }),
      rating_users: generateListItem({
        key: "rating_users",
        primary: "Rating users",
        secondary: contribution.rating_users || "N/A"
      }),
      rating_admins: generateListItem({
        key: "rating_admins",
        primary: "Rating admins",
        secondary: contribution.rating_admin_users || "N/A"
      }),
      downloads: generateListItem({
        key: "downloads",
        primary: "Downloads",
        secondary: contribution.download_counter || "N/A"
      }),
      likes: generateListItem({
        key: "likes",
        primary: "Likes",
        secondary: contribution.favourite_count || "N/A"
      }),
      categories: generateListItem({
        key: "categories",
        primary: "Categories",
        secondary: (
          <span className="no-text-clamp">
            {(contribution.categories &&
              contribution.categories.map(c => c.name).join(", ")) ||
              "N/A"}
          </span>
        ),
        icon: (
          <EditDialogWithCheckboxes
            title={"Categories"}
            contribution={contribution}
            updateKey={"category"}
            updateType={"array"}
            items={categories}
            selectedItems={contribution.categories}
            updateContribution={updateContribution}
          />
        )
      }),
      tags: generateListItem({
        key: "tags",
        primary: "Keywords",
        secondary: this.printTags({
          human: contribution.tags,
          admin: contribution.tags_admin_high,
          machine: contribution.tags_machine
        }),
        icon: (
          <EditDialogWithTextArea
            title={"Keywords"}
            highlightTags={
              contribution.tags_admin_high &&
              contribution.tags_admin_high.map(c => c.name).join(",")
            }
            updateKey={["tags", "tags_machine", "tags_admin_high"]}
            contribution={contribution}
            updateContribution={updateContribution}
            autoSuggestTags={contribution.tags_metadata}
          />
        )
      })
      /*human_keywords: generateListItem({
        key: "human_keywords",
        primary: "Human keywords",
        secondary: (<span className="no-text-clamp">{(contribution.tags && contribution.tags.map(c => c.name).join(', ')) || 'N/A'}</span>),
        icon: (<EditDialogWithTextArea title={'Human keywords'} highlightTags={(contribution.tags_admin_high && contribution.tags_admin_high.map(c => c.name).join(','))} updateKey="tags" contribution={contribution} updateContribution={updateContribution} />)
      }),
      admin_keywords: generateListItem({
        key: "admin_keywords",
        primary: "Admin keywords",
        secondary: (<span className="no-text-clamp">{(contribution.tags_admin_high && contribution.tags_admin_high.map(c => c.name).join(', ')) || 'N/A'}</span>),
        icon: (<EditDialogWithTextArea title={'Admin keywords'} updateKey="tags_admin_high" contribution={contribution} updateContribution={updateContribution} />)
      }),
      machine_keywords: generateListItem({
        key: "machine_keywords",
        primary: "Machine keyword",
        secondary: (<span className="no-text-clamp">{(contribution.tags_machine && contribution.tags_machine.map(c => c.name).join(', ')) || 'N/A'}</span>),
        icon: (<EditDialogWithCheckboxes title={'Machine tags'} contribution={contribution} updateKey={'tags_machine'} updateType={'string'} items={contribution.tags_machine} selectedItems={contribution.tags_machine} updateContribution={updateContribution} />)
      })*/
    };
    let groups = clone(this.state.groups);
    let template = groups.map((group, key) => {
      group.items.forEach((item, key) => {
        if (typeof item === "string") {
          group.items[key] = this.items[item];
        }
      });
      let items = group.items;
      return (
        <div key={key}>
          <ListItem button onClick={item => this.openList(key)}>
            <ListItemText primary={group.name} />
            {group.open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={group.open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {items}
            </List>
          </Collapse>
        </div>
      );
    });
    return template;
  };

  openList(groupKey) {
    let groups = clone(this.state.groups);
    let open = !groups[groupKey].open;
    groups[groupKey].open = open;
    groups[groupKey].stateChanged = true;
    this.setState({
      groups: groups
    });
    this.saveDarwinHistory(groupKey, open);
  }

  saveDarwinHistory = (key, val) => {
    let darwinHistory = localStorage.getItem("darwinHistory") || "{}";
    darwinHistory = JSON.parse(darwinHistory);
    if (!darwinHistory[this.props.darwinKey]) {
      darwinHistory[this.props.darwinKey] = {};
    }
    darwinHistory[this.props.darwinKey][key] = {
      open: val
    };
    localStorage.setItem("darwinHistory", JSON.stringify(darwinHistory));
  };

  render() {
    let template = this.generateTemplate();
    if (this.state.groups) {
      return <List>{template}</List>;
    } else {
      return <div></div>;
    }
  }
}

export default ContributionDetails;
