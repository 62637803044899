import React, { Component } from "react";
import {
	HashRouter as Router,
	Switch,
	Route,
	Redirect,
	Link,
} from "react-router-dom";

import ModerationIcon from "@material-ui/icons/Edit";
import CurationIcon from "@material-ui/icons/Star";
import CollectionsIcon from "@material-ui/icons/Collections";
import LogoutIcon from "@material-ui/icons/ExitToApp";

import { AppBar, Button } from "@material-ui/core";

import Moderation from "./Moderation";
import Contribution from "./Contribution";
import Curation from "./Curation";
import Collections from "./Collections";
import CollectionContributions from "./CollectionContributions";
import GettyImages from "./GettyImages";
import api from "../api";
import authentication from "../authentication";
import Login from "./Login";
import { useRouter } from "../router-hooks";
import Cache from "../Cache";
import { addDays } from "date-fns";

function LazyComponent(Component, additionalProps) {
	return props => <Component {...props} {...additionalProps} />;
}

function Routes(props) {
	const { categories } = props;
	const [title, setTitle] = React.useState("");

	return (
		<Switch>
			<Route
				exact
				path="/moderation"
				component={LazyComponent(Moderation, {
					changeTitle: setTitle,
					categories: categories,
				})}
			/>
			<Route
				path="/moderation/:slug"
				render={props => {
					return (
						<Contribution
							changeTitle={setTitle}
							categories={categories}
							darwin="moderation"
							{...props}
						/>
					);
				}}
			/>
			<Route
				exact
				path="/curation"
				render={props => (
					<Curation changeTitle={setTitle} categories={categories} {...props} />
				)}
			/>
			<Route
				path="/curation/:slug"
				render={props => {
					return (
						<Contribution
							changeTitle={setTitle}
							categories={categories}
							darwin="curation"
							{...props}
						/>
					);
				}}
			/>
			<Route
				exact
				path="/collections"
				component={LazyComponent(Collections, {
					changeTitle: setTitle,
					categories: categories,
				})}
			/>
			<Route
				exact
				path="/collections/:collectionSlug/contributions"
				component={LazyComponent(CollectionContributions, {
					changeTitle: setTitle,
					categories: categories,
				})}
			/>
			<Route
				path="/collections/:collectionSlug/contributions/:slug"
				render={props => {
					return (
						<Contribution
							changeTitle={setTitle}
							categories={categories}
							darwin="collections"
							{...props}
						/>
					);
				}}
			/>
			<Route
				exact
				path="/gettyimages"
				render={props => <GettyImages {...props} />}
			/>
			<Redirect to="/moderation" />
		</Switch>
	);
}

function Header(props) {
	const { location } = useRouter();
	let getBackgroundColor = function(type) {
		let currentRoute = location.pathname;
		if (currentRoute.indexOf(type) > -1) {
			return "rgba(255, 255, 255, 0.2)";
		}
		return "transparent";
	};

	const ModerationLink = props => <Link to="/moderation" {...props} />;
	const CurationLink = props => <Link to="/curation" {...props} />;
	const CollectionsLink = props => <Link to="/collections" {...props} />;
	const GettyImagesLink = props => <Link to="/gettyimages" {...props} />;

	return (
		<AppBar
			position="static"
			style={{ padding: 10 }}
			children={
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<div style={{ height: "90%", display: "flex", alignItems: "center" }}>
						<Button
							component={ModerationLink}
							style={{
								color: "#FFF",
								margin: "0 10px",
								backgroundColor: getBackgroundColor("moderation"),
							}}>
							<ModerationIcon style={{ marginRight: 10 }} />
							MODERATION
						</Button>
						<Button
							component={CurationLink}
							style={{
								color: "#FFF",
								margin: "0 10px",
								backgroundColor: getBackgroundColor("curation"),
							}}>
							<CurationIcon style={{ marginRight: 10 }} />
							CURATION
						</Button>
						<Button
							component={CollectionsLink}
							style={{
								color: "#FFF",
								margin: "0 10px",
								backgroundColor: getBackgroundColor("collections"),
							}}>
							<CollectionsIcon style={{ marginRight: 10 }} />
							COLLECTIONS
						</Button>
						<Button
							component={GettyImagesLink}
							style={{
								color: "#FFF",
								margin: "0 10px",
								backgroundColor: getBackgroundColor("gettyimages"),
							}}>
							<CollectionsIcon style={{ marginRight: 10 }} />
							GETTY IMAGES
						</Button>
					</div>
					<div style={{ height: "90%", display: "flex", alignItems: "center" }}>
						<Button
							onClick={props.onLogout}
							style={{ color: "#FFF", margin: "0 10px" }}>
							<LogoutIcon style={{ marginRight: 10 }} />
							Logout
						</Button>
					</div>
				</div>
			}
		/>
	);
}

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			title: "Content Moderation Tool",
			isDrawerOpen: false,
			categories: Cache.getData("categories")
				? Cache.getData("categories").data
				: [],
			isAuthenticated: false,
		};

		this.changeTitle = this.changeTitle.bind(this);
		this.changeMenuItem = this.changeMenuItem.bind(this);
		this.setAuthenticated = this.setAuthenticated.bind(this);
	}

	componentDidMount() {
		this.setState({ isAuthenticated: authentication.isAuthenticated() });
		if (this.state.categories.length) {
			return false;
		}
		api.getCategories().then(categories => {
			categories = categories.map(c => ({
				id: c.id,
				slug: c.slug,
				name: c.name,
			}));
			Cache.setData({
				id: "categories",
				data: categories,
				expires: addDays(new Date(), 14),
			});
			this.setState({ categories });
		});
	}

	changeTitle(title) {
		this.setState({ title });
	}

	changeMenuItem(event, value) {
		if (value === "logout") {
			authentication.deauthenticate();
			this.setAuthenticated(false);
		}
	}

	setAuthenticated(isAuthenticated) {
		this.setState({ isAuthenticated });
	}

	render() {
		const { isAuthenticated } = this.state;
		const { setAuthenticated } = this;
		return (
			<>
				{isAuthenticated ? (
					<div>
						<Header
							onLogout={() => {
								authentication.deauthenticate();
								this.setAuthenticated(false);
							}}
						/>
						<Routes categories={this.state.categories} />
					</div>
				) : (
					<Login setAuthenticated={setAuthenticated} />
				)}
			</>
		);
	}
}

export default App;
