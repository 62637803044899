import React, { Component } from 'react'

import errorImage from "../images/error.svg";
import emptyImage from "../images/empty.svg";

class DisplayError extends Component {

  render() {
    let image = errorImage;

    if(this.props.code === 404){
        image = emptyImage;
    }
    return (
        <div className="display-error-page">
            <img src={image} alt={this.props.message} />
            <h1>{this.props.message}</h1>
            <h2>– {this.props.code} –</h2>
            <i>{this.props.details}</i>
            {this.props.actions}
        </div>
    )
  }
}

export default DisplayError;