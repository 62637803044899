import React, { Component } from "react";

import PropTypes from "prop-types";

import Autosuggest from "react-autosuggest";

import {
	Toolbar,
	Select,
	FormControl,
	InputLabel,
	MenuItem,
	Paper,
	TextField,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import api from "../api";

const styles = theme => ({
	container: {
		minWidht: 400,
		flexGrow: 1,
		position: "relative",
	},
	suggestionsContainerOpen: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing.unit,
		left: 0,
		right: 0,
	},
	suggestion: {
		display: "block",
	},
	suggestionsList: {
		margin: 0,
		padding: 0,
		listStyleType: "none",
	},
});

class ToolbarToFilterAndSort extends Component {
	constructor(props) {
		super(props);

		this.state = {
			suggestions: [],
			value:
				(props &&
					props.search &&
					props.search.searchParams &&
					props.search.searchParams.query) ||
				"",
		};

		this.onAutocompleteSelect = this.onAutocompleteSelect.bind(this);
		this.renderDropdowns = this.renderDropdowns.bind(this);
	}

	onAutocompleteSelect = (selectedObj, index, ds) => {
		if (selectedObj.searchParams && selectedObj.searchParams.query) {
			selectedObj.title = selectedObj.title.replace("Search for phrase ", "");
		}

		if (selectedObj.clear) {
			selectedObj.title = "";
			selectedObj.searchParams = { query: "" };
		}

		this.props.setSearch(selectedObj);
	};

	renderDropdowns(dropdowns, onChange) {
		return Object.entries(dropdowns).map(dropdown => {
			let options = dropdown[1].options.map(option => {
				return (
					<MenuItem key={`${dropdown[0]}-${option.value}`} value={option.value}>
						{option.title}
					</MenuItem>
				);
			});
			return (
				<FormControl
					key={dropdown[0]}
					htmlFor={dropdown[0]}
					style={{ width: "100%", maxWidth: 250, margin: "0 15px" }}>
					<InputLabel>{dropdown[1].title}</InputLabel>
					<Select
						value={dropdown[1].selectedValue}
						inputProps={{
							name: dropdown[0],
							id: dropdown[0],
						}}
						onChange={e => onChange(dropdown[0], e.target.value)}>
						{options}
					</Select>
				</FormControl>
			);
		});
	}

	renderSuggestion = (suggestion, { query, isHighlighted }) => {
		return (
			<MenuItem
				onClick={() => this.onAutocompleteSelect(suggestion)}
				selected={isHighlighted}
				component="div">
				{suggestion.title}
			</MenuItem>
		);
	};

	renderSuggestionsContainer(options) {
		const { containerProps, children } = options;

		return (
			<Paper {...containerProps} square>
				{children}
			</Paper>
		);
	}

	handleKeyDown(e) {
		if (e.key === "Enter" && e.target.value) {
			this.onAutocompleteSelect({
				title: `Search for phrase ${e.target.value}`,
				searchParams: {
					query: e.target.value,
				},
			});
		}
	}

	renderInput = inputProps => {
		const { classes, ref, ...other } = inputProps;

		return (
			<TextField
				fullWidth
				style={{ width: 240 }}
				label="Search"
				onKeyPress={e => this.handleKeyDown(e)}
				InputProps={{
					inputRef: ref,
					classes: {
						input: classes.input,
					},
					...other,
				}}
			/>
		);
	};

	handleSuggestionsFetchRequested = async ({ value }) => {
		let suggestions = await this.getSuggestions(value);
		this.setState({
			suggestions: suggestions,
		});
	};

	handleSuggestionsClearRequested = () => {
		this.setState({
			suggestions: [],
		});
	};

	handleChange = (event, { newValue }) => {
		this.setState({
			value: newValue,
		});
	};

	async getSuggestions(value) {
		const inputValue = value.trim().toLowerCase();
		const inputLength = inputValue.length;
		let search = {
			title: `Search for phrase ${inputValue}`,
			searchParams: { query: inputValue },
		};
		let clearSearch = {
			title: `Clear search`,
			clear: true,
			searchParams: { query: "" },
		};

		if (inputLength > 0) {
			let response = await api.getContributors(inputValue);
			const users = response.users
				? response.users.map(user => ({
						title: user.username,
						searchParams: { by_user: user.uri.match(/[0-9]+/)[0] },
				  }))
				: [];
			return [clearSearch, search, ...users];
		}
	}

	render() {
		const {
			filters,
			setFilter,
			sorters,
			setSorter,
			search,
			classes,
		} = this.props;
		const { renderDropdowns } = this;

		return (
			<Toolbar style={{ background: "#EAEAEA", padding: 20 }}>
				{search && (
					<Autosuggest
						style={{ minWidth: 300 }}
						theme={{
							container: classes.container,
							suggestionsContainerOpen: classes.suggestionsContainerOpen,
							suggestionsList: classes.suggestionsList,
							suggestion: classes.suggestion,
						}}
						renderInputComponent={this.renderInput}
						suggestions={this.state.suggestions}
						onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
						onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
						renderSuggestionsContainer={this.renderSuggestionsContainer}
						getSuggestionValue={suggestion => suggestion.title}
						renderSuggestion={this.renderSuggestion}
						inputProps={{
							classes,
							value: this.state.value,
							onChange: this.handleChange,
						}}
					/>
				)}
				{filters && renderDropdowns(filters, setFilter)}
				{renderDropdowns(sorters, setSorter)}
			</Toolbar>
		);
	}
}

ToolbarToFilterAndSort.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ToolbarToFilterAndSort);
