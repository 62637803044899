import React, { Component } from "react";
import { HotKeys } from "react-hotkeys";
import clone from "clone";

import {
	Avatar,
	List,
	ListSubheader,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	Checkbox,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	DialogContentText,
	CircularProgress,
	TextField,
	Chip,
} from "@material-ui/core";

const keyMap = {
	update: ["enter"],
};

class ContributionKeywordsActions extends Component {
	constructor(props) {
		super(props);

		let tags = this.combineTags();

		this.state = {
			isLoading: false,
			isDialogOpen: false,
			newTag: "",
			matchedTags: [],
			removeTags: [],
			selectedItems: props.selectedItems || [],
			tags: "", // (this.props.contribution[this.props.updateKey] || []).map(tag => tag.name).join(',')
			...tags,
		};

		this.toggleDialog = this.toggleDialog.bind(this);
		this.onTextareaChange = this.onTextareaChange.bind(this);
		this.update = this.update.bind(this);
	}

	combineTags = setState => {
		let contributions = {};
		if (!this.state) {
			contributions = this.props.selectedItems;
		} else {
			contributions = this.state.selectedItems;
		}
		let matchTags = {};
		let allTags = [];
		let tags = [];
		contributions.forEach(contribution => {
			let contributionTags = {};
			contribution.tags.forEach(tag => {
				contributionTags[tag.slug] = tag;
			});
			contribution.tags_machine.forEach(tag => {
				contributionTags[tag.slug] = tag;
			});
			allTags.push({
				allTags: contributionTags,
			});
		});
		allTags.forEach(contr => {
			Object.keys(contr.allTags).forEach(key => {
				let tag = contr.allTags[key];
				if (!matchTags[tag.slug]) {
					matchTags[tag.slug] = {
						count: 1,
						...tag,
					};
				} else {
					matchTags[tag.slug].count += 1;
				}
			});
		});
		Object.keys(matchTags).forEach(slug => {
			let tag = matchTags[slug];
			if (tag.count === contributions.length) {
				tags.push(tag);
			}
		});
		if (setState) {
			this.setState({
				matchedTags: matchTags,
			});
		} else {
			return {
				matchedTags: matchTags,
			};
		}
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedItems.length > 1) {
			this.setState(
				{
					selectedItems: nextProps.selectedItems,
				},
				() => {
					this.combineTags(true);
				}
			);
		}
		// this.setState({ tags: (nextProps.contribution[this.props.updateKey] || []).map(tag => tag.name).join(',') })
	}

	toggleDialog() {
		if (this.state.selectedItems.length >= 50) {
			window.alert(
				"You shall not tag! Apparently you have selected " +
					this.state.selectedItems.length +
					" contributions. That is not allowed, as you might crash our system. Maximum is 50. Don't blame me, Im just a simple messenger. Lannisters sends their regards."
			);
			return false;
		}
		if (this.state.isLoading) {
			return;
		}

		this.setState({ isDialogOpen: !this.state.isDialogOpen });
	}

	onTextareaChange(e) {
		this.setState({ tags: e.target.value });
	}

	update() {
		if (this.state.isLoading) {
			return;
		}

		this.setState({
			isLoading: true,
		});

		let removeTags = this.state.removeTags;
		let addTags = [];
		let returnTags = [];

		if (this.props.type === "delete") {
			returnTags = removeTags;
		} else {
			this.state.tags.split(",").forEach(tag => {
				returnTags.push(tag);
			});
		}

		this.props.updateContribution(this.props.type, returnTags, () => {
			this.setState({
				isLoading: false,
				isDialogOpen: false,
			});
		});
	}

	removeTag = tag => {
		let tags = this.state.tags;
		let chips = tags.split(",");
		chips = chips.filter(chip => {
			return tag !== chip;
		});
		tags = chips.join(",");
		this.setState({
			tags: tags,
		});
	};

	commaToChips = () => {
		let tags = this.state.tags;

		let styles = {
			chip: {
				margin: 4,
			},
			wrapper: {
				display: "flex",
				flexWrap: "wrap",
			},
		};

		let chips = tags.split(",").map((tag, key) => {
			let style = {
				margin: 4,
				opacity: 1,
			};
			let color = null;
			let svgStyle = {};
			if (tag.indexOf(this.state.newTag) === -1) {
				style.opacity = 0.5;
			}
			if (tag.toLowerCase() === this.state.newTag.toLowerCase()) {
				style.boxShadow = "0px 0px 26px 4px rgba(119, 198, 245, 0.6)";
				color = "#FFFFFF";
				svgStyle.fill = "#FFFFFF";
				svgStyle.color = "#FFFFFF";
			}
			if (!tag) {
				return <div key={key}></div>;
			}
			return (
				<Chip
					key={key}
					style={style}
					label={tag}
					onDelete={() => this.removeTag(tag)}
				/>
			);
		});
		return <div style={styles.wrapper}>{chips}</div>;
	};

	countTags = () => {
		return this.state.tags.length ? this.state.tags.split(",").length : 0;
	};

	newTagOnChange = e => {
		this.setState({ newTag: e.target.value });
	};

	tagOnChange = e => {
		this.setState({ tags: e.target.value });
	};

	handleNewTag = event => {
		if (event.keyCode === 13 && this.state.newTag) {
			let result = this.state.tags + "," + this.state.newTag;
			if (!this.state.tags) {
				result = this.state.newTag;
			}
			this.setState({
				tags: result,
				newTag: "",
			});
		}
	};

	deleteTag(key) {
		let matchedTags = clone(this.state.matchedTags);
		let tag = matchedTags[key];
		let removeTags = clone(this.state.removeTags);
		if (removeTags.indexOf(key) > -1) {
			removeTags.splice(removeTags.indexOf(key), 1);
		} else {
			removeTags.push(key);
		}
		this.setState({
			matchedTags: matchedTags,
			removeTags: removeTags,
		});
	}

	render() {
		const {
			isLoading,
			isDialogOpen,
			tags,
			newTag,
			removeTags,
			matchedTags,
		} = this.state;
		const { title, type } = this.props;
		const {
			toggleDialog,
			update,
			handleNewTag,
			newTagOnChange,
			countTags,
		} = this;

		const handlers = {
			update: isDialogOpen && this.update,
		};

		let chips = this.commaToChips();
		let tagsList = "";

		if (type === "delete") {
			let matchTags = Object.keys(matchedTags)
				.sort((a, b) => {
					a = matchedTags[a];
					b = matchedTags[b];
					return a.count - b.count;
				})
				.reverse();

			tagsList = matchTags.map(key => {
				let tag = matchedTags[key];
				return (
					<ListItem
						key={key}
						role={undefined}
						dense
						button
						onClick={() => this.deleteTag(key)}>
						<Avatar style={{ marginRight: 15 }}>{tag.count}</Avatar>
						<ListItemText
							style={{ marginRight: 15 }}
							primary={`${tag.name} (${tag.slug})`}
						/>
						<ListItemSecondaryAction>
							<Checkbox
								checked={removeTags.indexOf(key) > -1}
								tabIndex={-1}
								disableRipple
								onClick={() => this.deleteTag(key)}
							/>
						</ListItemSecondaryAction>
					</ListItem>
				);
			});
		}

		return (
			<div>
				<List>
					<ListSubheader>{title}</ListSubheader>
					<div className="list-selects">
						<Button
							className="button"
							variant="contained"
							color="primary"
							fullWidth
							onClick={toggleDialog}>
							{this.props.buttonLabel}
						</Button>
					</div>
				</List>
				<HotKeys keyMap={keyMap} handlers={handlers}>
					<span className="edit-button">
						<Dialog open={isDialogOpen} onClose={toggleDialog}>
							<DialogTitle>{title}</DialogTitle>
							<DialogContent>
								{isLoading ? (
									<CircularProgress />
								) : (
									<div>
										{this.props.type === "delete" ? (
											<List>{tagsList}</List>
										) : (
											<div>
												{chips}
												{countTags() === 0 ? (
													<DialogContentText>No tags</DialogContentText>
												) : null}
												{!this.props.onlyRemove ? (
													<div>
														<TextField
															fullWidth
															style={{ marginTop: 15 }}
															label={`Add new tag`}
															onChange={newTagOnChange}
															onKeyDown={handleNewTag}
															value={newTag}
														/>
														<TextField
															multiline
															fullWidth
															style={{ marginTop: 20 }}
															label={"Tag row"}
															onChange={this.tagOnChange}
															value={tags}
														/>
													</div>
												) : null}
											</div>
										)}
									</div>
								)}
							</DialogContent>
							<DialogActions>
								<Button onClick={toggleDialog}>Cancel</Button>
								<Button color="primary" onClick={update}>
									{this.props.type === "delete" ? "Delete" : "Add tags"}
								</Button>
							</DialogActions>
						</Dialog>
					</span>
				</HotKeys>
			</div>
		);
	}
}

export default ContributionKeywordsActions;
