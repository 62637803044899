import React, { Component } from "react";

import {
	CircularProgress,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
} from "@material-ui/core";

import ContainerWithRightSidebar from "../components/ContainerWithRightSidebar";
import ToolbarToFilterAndSort from "../components/ToolbarToFilterAndSort";
import ContributionsGrid from "../components/ContributionsGrid";
import ContributionActions from "../components/ContributionActions";
import ContributionDetails from "../components/ContributionDetails";
import Pagination from "../components/Pagination";
import api from "../api";
import ContributionDarwin from "../ContributionDarwin";
import Easter from "./Easter";
import Cache from "../Cache";

class CheckImages extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			isDialogOpen: false,
		};

		this.toggleDialog = this.toggleDialog.bind(this);
		this.updateAllContributions = this.updateAllContributions.bind(this);
	}

	toggleDialog() {
		this.setState({ isDialogOpen: !this.state.isDialogOpen });
	}

	updateAllContributions() {
		this.setState({ isLoading: true });

		this.props.updateAllContributions().then(() => {
			this.setState({ isLoading: false, isDialogOpen: false });
		});
	}

	render() {
		const { isLoading, isDialogOpen } = this.state;
		const { toggleDialog, updateAllContributions } = this;

		return (
			<div>
				<Button onClick={toggleDialog} variant="contained">
					Check all images
				</Button>
				<Dialog open={isDialogOpen} onClose={!isLoading && toggleDialog}>
					{isLoading ? (
						<CircularProgress />
					) : (
						<div>
							<DialogTitle>Check all images</DialogTitle>
							<DialogContent>
								<p>You will set images to:</p>
								<p>
									Image status: <strong>Published</strong>
								</p>
								<p>
									Explicitness level: <strong>Safe</strong>
								</p>
								<p>
									Model release status: <strong>Not needed</strong>
								</p>
							</DialogContent>
							<DialogActions>
								<Button onClick={!isLoading && toggleDialog}>Cancel</Button>
								<Button
									color="primary"
									onClick={!isLoading && updateAllContributions}>
									Update
								</Button>
							</DialogActions>
						</div>
					)}
				</Dialog>
			</div>
		);
	}
}

const ratingFilters = [
	{
		title: "All",
		value: "all",
	},
	{
		title: "5",
		value: "5",
	},
	{
		title: "4",
		value: "4",
	},
	{
		title: "3",
		value: "3",
	},
	{
		title: "2",
		value: "2",
	},
	{
		title: "1",
		value: "1",
	},
	{
		title: "Not rated",
		value: "0",
	},
];

const imageStatusFilters = [
	{
		title: "All",
		value: "all",
	},
	{
		title: "Draft",
		value: "draft",
	},
	{
		title: "Pending review",
		value: "pending-review",
	},
	{
		title: "Published",
		value: "published",
	},
	{
		title: "Deleted",
		value: "deleted",
	},
];

const explicitnessLevelFilters = [
	{
		title: "All",
		value: "all",
	},
	{
		title: "Inappropriate",
		value: "inappropriate",
	},
	{
		title: "Allowing",
		value: "allowing",
	},
	{
		title: "Moderate",
		value: "moderate",
	},
	{
		title: "Safe",
		value: "safe",
	},
];

const releaseStatusFilters = [
	{
		title: "All",
		value: "all",
	},
	{
		title: "Draft",
		value: "draft",
	},
	{
		title: "Pending",
		value: "pending",
	},
	{
		title: "Not trusted",
		value: "not-trusted",
	},
	{
		title: "Needed",
		value: "needed",
	},
	{
		title: "Not needed",
		value: "not-needed",
	},
	{
		title: "Rejected",
		value: "rejected",
	},
	{
		title: "Released",
		value: "released",
	},
	{
		title: "Deleted",
		value: "deleted",
	},
	{
		title: "Unknown",
		value: "unknown",
	},
];

const categoryFilters = [
	{
		title: "All",
		value: "all",
	},
	{
		title: "Uncategorized",
		value: "none",
	},
];

const dateSorters = [
	{
		title: "Newer first",
		value: "desc",
	},
	{
		title: "Older first",
		value: "asc",
	},
];

class Moderation extends Component {
	constructor(props) {
		super(props);

		const urlParams = new URLSearchParams(this.props.location.search);

		let searchParams = {};
		if (urlParams.get("query")) {
			searchParams.query = urlParams.get("query");
		}

		if (urlParams.get("by_user")) {
			searchParams.by_user = urlParams.get("by_user");
		}

		this.state = {
			isLoading: false,
			currentPage: urlParams.get("page_nr") || 1,
			perPage: urlParams.get("page_limit") || 200,
			totalResults: null,
			totalPages: null,
			contributions: [],
			selectedContributions: [],
			filters: {
				"filter-by-image-status": {
					title: "Image status",
					key: "status",
					selectedValue: urlParams.get("status") || "published",
					options: imageStatusFilters,
				},
				"filter-by-explicitness-level": {
					title: "Explicitness level",
					key: "explicit_level",
					selectedValue: urlParams.get("explicit_level") || "safe",
					options: explicitnessLevelFilters,
				},
				"filter-by-model-release-status": {
					title: "Model release status",
					key: "release_status",
					selectedValue: urlParams.get("release_status") || "not-needed",
					options: releaseStatusFilters,
				},
				"filter-by-category": {
					title: "Category",
					key: "categories",
					selectedValue: urlParams.get("categories") || "all",
					options: categoryFilters.concat(
						this.props.categories.map(c => ({ title: c.name, value: c.slug }))
					),
				},
				"filter-by-rating": {
					title: "Rating",
					key: "rating_admin_users",
					selectedValue: urlParams.get("rating_admin_users") || "all",
					options: ratingFilters,
				},
			},
			sorters: {
				"sort-by-date": {
					title: "Sort",
					key: "sort_order",
					selectedValue: urlParams.get("sort_order") || "desc",
					options: dateSorters,
				},
			},
			search: {
				title: urlParams.get("searchTitle") || "",
				searchParams: searchParams,
			},
		};

		this.getContributions = this.getContributions.bind(this);
		this.fetchContributions = this.fetchContributions.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.setSorter = this.setSorter.bind(this);
		this.setSearch = this.setSearch.bind(this);
		this.prevPage = this.prevPage.bind(this);
		this.nextPage = this.nextPage.bind(this);
		this.selectContribution = this.selectContribution.bind(this);
		this.changeTofullscreenMode = this.changeTofullscreenMode.bind(this);
		this.updateContributions = this.updateContributions.bind(this);
		this.updateAllContributions = this.updateAllContributions.bind(this);
		this.updateContribution = this.updateContribution.bind(this);
	}

	componentWillUnmount() {
		this.mounted = false;
	}
	componentDidMount() {
		this.props.changeTitle("Moderation");
		this.mounted = true;
		console.log("Mounting");
		if (!this.state.isLoading && !this.state.contributions.length) {
			this.fetchContributions();
		}
	}

	componentWillReceiveProps(nextProps) {
		//TODO: Move categories to redux store some day...
		if (this.props.categories !== nextProps.categories) {
			const categories = nextProps.categories.map(c => ({
				title: c.name,
				value: c.slug,
			}));
			const categoriesFilter = { ...this.state.filters["filter-by-category"] };
			categoriesFilter.options = categoryFilters.concat(categories);

			this.setState({
				filters: {
					...this.state.filters,
					"filter-by-category": categoriesFilter,
				},
			});
		}
	}

	getContributions(cb) {
		const params = {
			page_nr: this.state.currentPage,
			page_limit: this.state.perPage,
			sort_by: "created_on",
			...Object.entries(this.state.filters).reduce((newFilters, filter) => {
				if (filter[1].selectedValue !== "all") {
					return {
						...newFilters,
						[filter[1].key]: filter[1].selectedValue,
					};
				} else {
					return newFilters;
				}
			}, {}),
			...Object.entries(this.state.sorters).reduce((newSorters, sorter) => {
				return {
					...newSorters,
					[sorter[1].key]: sorter[1].selectedValue,
				};
			}, {}),
			...this.state.search.searchParams,
		};

		const paramsUrl = Object.entries(params)
			.map(([key, value]) => `${key}=${value}`)
			.join("&");

		const searchString = `?${paramsUrl}&searchTitle=${this.state.search.title}`;
		const paramsId = encodeURIComponent(searchString);

		const parseData = data => {
			const perPage = parseInt(data.per_page, 10);
			const totalResults = parseInt(data.total_count, 10);
			//TODO: Reduce contributions object
			const contributions = data.data;
			Cache.setData({
				id: "contributions__" + paramsId,
				data: data,
				stateOnly: true,
			});
			this.props.history.push(searchString);

			if (this.mounted) {
				this.setState(
					{
						isLoading: false,
						currentPage: parseInt(data.page, 10),
						perPage,
						totalResults,
						totalPages: Math.ceil(totalResults / perPage),
						contributions,
					},
					() => {
						if (typeof cb === "function") {
							cb(contributions);
						}
					}
				);
			}
		};

		if (Cache.getData("contributions__" + paramsId)) {
			return parseData(Cache.getData("contributions__" + paramsId).data);
		}

		return api.getContributions(params).then(parseData);
	}

	fetchContributions() {
		this.setState(
			{ isLoading: true, contributions: [], selectedContributions: [] },
			() => {
				this.getContributions();
			}
		);
	}

	setFilter(filterId, selectedValue) {
		const filters = Object.keys(this.state.filters).reduce((newFilters, id) => {
			if (id !== filterId) {
				return { ...newFilters, [id]: this.state.filters[id] };
			} else {
				return {
					...newFilters,
					[id]: { ...this.state.filters[id], selectedValue },
				};
			}
		}, {});

		this.setState({ filters, currentPage: 1 }, this.fetchContributions);
	}

	setSorter(sortId, selectedValue) {
		const sorters = Object.keys(this.state.sorters).reduce((newSorters, id) => {
			if (id !== sortId) {
				return { ...newSorters, [id]: this.state.sorters[id] };
			} else {
				return {
					...newSorters,
					[id]: { ...this.state.sorters[id], selectedValue },
				};
			}
		}, {});

		this.setState({ sorters, currentPage: 1 }, this.fetchContributions);
	}

	setSearch(selectedValue) {
		this.setState(
			{ search: selectedValue, currentPage: 1 },
			this.fetchContributions
		);
	}

	prevPage() {
		const currentPage = this.state.currentPage - 1;

		this.setState({ currentPage }, this.fetchContributions);
	}

	nextPage() {
		const currentPage = this.state.currentPage + 1;

		this.setState({ currentPage }, this.fetchContributions);
	}

	selectContribution(slug, selectMode) {
		const isSelected = this.state.selectedContributions.indexOf(slug) > -1;

		switch (selectMode) {
			case "single": {
				this.setState({ selectedContributions: isSelected ? [] : [slug] });
				break;
			}
			case "single-multiple": {
				let selectedContributions = [];

				if (isSelected) {
					selectedContributions = this.state.selectedContributions.filter(
						c => c !== slug
					);
				} else {
					selectedContributions = [slug, ...this.state.selectedContributions];
				}

				this.setState({ selectedContributions });
				break;
			}
			case "multiple": {
				const firstIndex = this.state.contributions.findIndex(
					c => c.slug === this.state.selectedContributions[0]
				);
				const lastIndex = this.state.contributions.findIndex(
					c => c.slug === slug
				);

				const multipleSelectedContributions = this.state.contributions
					.slice(
						firstIndex < lastIndex ? firstIndex : lastIndex,
						lastIndex > firstIndex ? lastIndex + 1 : firstIndex
					)
					.map(c => c.slug);

				const selectedContributions = [
					...multipleSelectedContributions,
					...this.state.selectedContributions.filter(
						c => !multipleSelectedContributions.includes(c.slug)
					),
				];

				this.setState({ selectedContributions });
				break;
			}
			default:
		}
	}

	changeTofullscreenMode(slug, force) {
		if (
			!force &&
			(this.state.selectedContributions.length !== 1 ||
				slug !== this.state.selectedContributions[0])
		) {
			return false;
		}

		const urlParams = new URLSearchParams(this.props.location.search);
		this.props.history.push(
			`/moderation/${slug}?${urlParams.toString()}`,
			this.state.contributions
		);

		return false;
	}

	updateAllContributions() {
		const ids = this.state.contributions.map(c => c.django_id);
		const actions = [
			"explicitly_level__safe",
			"model_release__not_needed",
			"status__publish",
		];

		return this.updateContributions(ids, actions);
	}

	updateContributions(ids, actions) {
		return new Promise((resolve, reject) => {
			const updateParams = {
				ids: ids.join(),
				actions_to_update: actions.join(),
			};

			return api.bulkUpdateContributions(updateParams).then(results => {
				this.getContributions(() => {
					resolve();
				});
			});
		});
	}

	updateContribution(slug, params) {
		return api.updateContribution(slug, params).then(updatedContribution => {
			const contributions = this.state.contributions.map(c => {
				if (~~c.django_id === ~~updatedContribution.id) {
					c.categories = updatedContribution.category;
					c.tags_admin_high = updatedContribution.tags_admin_high;
					c.tags = updatedContribution.tags;
					c.tags_machine = updatedContribution.tags_machine;
				}

				return c;
			});

			this.setState({ contributions });
		});
	}

	render() {
		const {
			isLoading,
			currentPage,
			perPage,
			totalPages,
			contributions,
			selectedContributions,
			filters,
			sorters,
			search,
		} = this.state;

		const { categories } = this.props;

		const {
			setFilter,
			setSorter,
			setSearch,
			prevPage,
			nextPage,
			selectContribution,
			changeTofullscreenMode,
			updateContributions,
			updateAllContributions,
			updateContribution,
		} = this;

		const selectedContributionsObjects = contributions.filter(c =>
			selectedContributions.includes(c.slug)
		);
		return (
			<div>
				<Easter />
				<ContainerWithRightSidebar
					stickyOffset={120}
					content={
						<div>
							<ToolbarToFilterAndSort
								filters={filters}
								setFilter={setFilter}
								sorters={sorters}
								setSorter={setSorter}
								search={search}
								setSearch={setSearch}
							/>

							{isLoading ? (
								<div className="loader">
									<CircularProgress />
								</div>
							) : (
								<ContributionsGrid
									contributions={contributions}
									selectedContributions={selectedContributions}
									selectContribution={selectContribution}
									changeTofullscreenMode={changeTofullscreenMode}
								/>
							)}

							{totalPages !== null ? (
								<Pagination
									isDisabled={isLoading}
									currentPage={currentPage}
									perPage={perPage}
									totalPages={totalPages}
									goBack={prevPage}
									goForward={nextPage}
								/>
							) : null}

							{!isLoading ? (
								<div className="check-images">
									<CheckImages
										updateAllContributions={updateAllContributions}
									/>
								</div>
							) : null}
						</div>
					}
					sidebar={
						<div>
							{selectedContributions.length > 0 ? (
								<ContributionActions
									contributions={selectedContributionsObjects}
									updateContributions={updateContributions}
								/>
							) : null}

							{selectedContributions.length === 1 ? (
								<ContributionDetails
									groups={ContributionDarwin.moderation}
									darwinKey={"moderation"}
									categories={categories}
									contribution={selectedContributionsObjects[0]}
									updateContribution={updateContribution}
									setSearch={setSearch}
								/>
							) : null}
						</div>
					}
					showSidebar={selectedContributions.length > 0}
				/>
			</div>
		);
	}
}

export default Moderation;
