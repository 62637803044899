import React, { Component } from "react";

import LeftIcon from "@material-ui/icons/ChevronLeft";
import RightIcon from "@material-ui/icons/ChevronRight";
import DownloadIcon from "@material-ui/icons/CloudDownload";

import ContributionDetails from "../components/ContributionDetails";
import AddToCollection from "../components/AddToCollection";
import RemoveFromCollection from "../components/RemoveFromCollection";
import ContainerWithRightSidebar from "../components/ContainerWithRightSidebar";
import Image from "../components/Image";
import Mousetrap from "mousetrap";
import api from "../api";
import authentication from "../authentication";
import ContributionDarwin from "../ContributionDarwin";

import {
	Toolbar,
	Chip,
	Avatar,
	IconButton,
	Button,
	Snackbar,
	Slide,
} from "@material-ui/core";
import Cache from "../Cache";

const qualities = ["web", "source"];

function SnackbarTransition(props) {
	return <Slide direction="left" {...props} />;
}

class Contribution extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contributions: this.props.location.state || null,
			quality: 0,
			showDetailsPane: true,
			snackbarMessage: "",
			section: this.props.location.pathname.split("/")[1],
			showRemoveFromCollectionDialog: false,
		};

		this.previousContribution = this.previousContribution.bind(this);
		this.nextContribution = this.nextContribution.bind(this);
		this.rateImage = this.rateImage.bind(this);
		this.downloadImage = this.downloadImage.bind(this);
		this.toggleDetailsPane = this.toggleDetailsPane.bind(this);
		this.toggleQuality = this.toggleQuality.bind(this);
		this.resetSnackbar = this.resetSnackbar.bind(this);
		this.goBack = this.goBack.bind(this);
		this.updateContribution = this.updateContribution.bind(this);
		this.addContributionToCollection = this.addContributionToCollection.bind(
			this
		);
	}

	componentDidMount() {
		const paramsId = encodeURIComponent(this.props.location.search);
		let cache = Cache.getData("contributions__" + paramsId);
		const contributions =
			this.state.contributions || (cache ? cache.data.data : false);
		if (contributions) {
			const contribution = contributions.find(
				c => c.slug === this.props.match.params.slug
			);

			this.props.changeTitle(contribution.slug);
			this.setState({ contributions });

			//TODO: Change to contribution name
		} else {
			const urlParams = new URLSearchParams(this.props.location.search);

			const urlParamsObj = {};
			for (const [key, val] of urlParams.entries()) {
				urlParamsObj[key] = val;
			}

			api.getContributions(urlParamsObj).then(data => {
				const contribution = data.data.find(
					c => c.slug === this.props.match.params.slug
				);

				//TODO: Change to contribution name
				this.props.changeTitle(contribution.slug);

				this.setState({ contributions: data.data });
			});
		}

		Mousetrap.bind("left", this.previousContribution);
		Mousetrap.bind("right", this.nextContribution);
		Mousetrap.bind("esc", this.goBack);
		Mousetrap.bind("d", this.downloadImage);
		Mousetrap.bind("i", this.toggleDetailsPane);
		Mousetrap.bind("q", this.toggleQuality);

		if (this.state.section !== "moderation") {
			Mousetrap.bind(["1", "2", "3", "4", "5"], this.rateImage);
		}
		/* if(this.state.section === "collections"){
        Mousetrap.bind('r', () => {
            this.setState({
                showRemoveFromCollectionDialog: true
            })
        });
    } */
	}

	componentWillUnmount() {
		Mousetrap.unbind([
			"left",
			"right",
			"esc",
			"1",
			"2",
			"3",
			"4",
			"5",
			"d",
			"i",
			"q",
			"r",
		]);
	}

	previousContribution() {
		const index = this.state.contributions.findIndex(
			c => c.slug === this.props.match.params.slug
		);

		if (index > 0) {
			const contribution = this.state.contributions[index - 1];

			this.props.changeTitle(contribution.slug);

			this.setState({ quality: 0 });
			this.props.history.push(
				`${contribution.slug}${this.props.location.search}`
			);
		} else {
			const urlParams = new URLSearchParams(this.props.location.search);
			if (urlParams.get("page_nr") > 1) {
				const urlParamsObj = {};
				for (const [key, val] of urlParams.entries()) {
					urlParamsObj[key] = val;
				}

				urlParamsObj.page_nr = parseInt(urlParamsObj.page_nr, 10) - 1;

				api.getContributions(urlParamsObj).then(data => {
					const contribution = data.data[data.data.length - 1];

					this.props.changeTitle(contribution.slug);

					const urlParams = new URLSearchParams(this.props.location.search);
					urlParams.set("page_nr", data.page);
					this.setState({ quality: 0 });
					this.props.history.push(
						`${contribution.slug}?${urlParams.toString()}`
					);

					this.setState({ contributions: data.data });
				});
			}
		}

		return false;
	}

	nextContribution() {
		const index = this.state.contributions.findIndex(
			c => c.slug === this.props.match.params.slug
		);

		if (index < this.state.contributions.length - 1) {
			const contribution = this.state.contributions[index + 1];

			this.props.changeTitle(contribution.slug);

			this.setState({ quality: 0 });
			this.props.history.push(
				`${contribution.slug}${this.props.location.search}`
			);
		} else {
			const urlParams = new URLSearchParams(this.props.location.search);
			//TODO: Check if next page is available
			if (true) {
				const urlParamsObj = {};
				for (const [key, val] of urlParams.entries()) {
					urlParamsObj[key] = val;
				}

				urlParamsObj.page_nr = parseInt(urlParamsObj.page_nr, 10) + 1;

				this.setState({
					loading: true,
				});

				api.getContributions(urlParamsObj).then(data => {
					const contribution = data.data[0];

					if (contribution) {
						this.props.changeTitle(contribution.slug);

						const urlParams = new URLSearchParams(this.props.location.search);
						urlParams.set("page_nr", data.page);
						this.setState({ quality: 0 });
						this.props.history.push(
							`${contribution.slug}?${urlParams.toString()}`
						);

						this.setState({ contributions: data.data, loading: false });
					}
				});
			}
		}

		return false;
	}

	rateImage(event, key) {
		api
			.rateContribution(this.props.match.params.slug, key)
			.then(() => {
				this.setState({ snackbarMessage: "Contribution was rated!" });
			})
			.catch(() => {
				this.setState({
					snackbarMessage: "You have already rated this contribution!",
				});
			});

		return false;
	}

	downloadImage() {
		window.open(
			this.state.contributions
				.find(c => c.slug === this.props.match.params.slug)
				.photos.find(p => p.name === "source").url
		);

		return false;
	}

	toggleDetailsPane() {
		this.setState({ showDetailsPane: !this.state.showDetailsPane });

		return false;
	}

	toggleQuality() {
		this.setState({ quality: ~~!this.state.quality });
	}

	resetSnackbar() {
		this.setState({ snackbarMessage: "" });
	}

	goBack() {
		if (this.state.loading) {
			return false;
		}
		let path = this.props.location.pathname.split("/")[1];
		if (this.props.location.pathname.indexOf("collections") > -1) {
			path = this.props.location.pathname.replace(
				"/" + this.props.match.params.slug,
				""
			);
			this.props.history.push(`${path}${this.props.location.search}`);
		} else {
			this.props.history.push(`/${path}${this.props.location.search}`);
		}

		return false;
	}

	updateContribution(slug, params) {
		return api.updateContribution(slug, params).then(updatedContribution => {
			const contributions = this.state.contributions.map(c => {
				if (~~c.django_id === ~~updatedContribution.id) {
					c.categories = updatedContribution.category;
					c.tags_admin_high = updatedContribution.tags_admin_high;
					c.tags = updatedContribution.tags;
					c.tags_machine = updatedContribution.tags_machine;
				}

				return c;
			});

			this.setState({ contributions });
		});
	}

	addContributionToCollection(selectedCollection) {
		const userId = authentication.getUser().id;

		return api.addToCollection(userId, selectedCollection, [
			this.props.match.params.slug,
		]);
	}

	removeFromCollection = contribution => {
		const userId = authentication.getUser().id;
		this.setState({
			showRemoveFromCollectionDialog: false,
		});
		api
			.removeFromCollection(userId, this.props.match.params.collectionSlug, [
				contribution,
			])
			.then(res => {
				this.setState({
					isLoading: true,
					contributions: [],
				});
				this.props.history.push(
					`/collections/${this.props.match.params.collectionSlug}/contributions`
				);
			});
	};

	render() {
		const { contributions, quality } = this.state;
		const { categories } = this.props;
		const {
			updateContribution,
			addContributionToCollection,
			downloadImage,
		} = this;

		const contribution =
			contributions &&
			contributions.find(c => c.slug === this.props.match.params.slug);

		return (
			<div>
				{/* <RemoveFromCollection open={this.state.showRemoveFromCollectionDialog} onSubmit={this.removeFromCollection} onCancel={() => this.setState({showRemoveFromCollectionDialog:false})} /> */}
				<ContainerWithRightSidebar
					stickyOffset={64}
					content={
						contribution ? (
							<div style={{ position: "relative" }}>
								<IconButton
									className="left-arrow"
									onClick={this.previousContribution}>
									<LeftIcon />
								</IconButton>
								<IconButton
									className="right-arrow"
									onClick={this.nextContribution}>
									<RightIcon />
								</IconButton>

								<Image
									url={
										contribution.photos.find(p => p.name === qualities[quality])
											.url
									}
									size="contain"
									containerStyle={{
										width: "100%",
										height: "calc(100vh - 64px)",
									}}
								/>
								<Toolbar
									style={{ padding: "0px 10px", backgroundColor: "#EFEFEF" }}>
									<Chip
										style={{ marginRight: 8 }}
										label="Back"
										avatar={<Avatar>ESC</Avatar>}
									/>
									{this.state.section !== "moderation" ? (
										<Chip
											style={{ marginRight: 8 }}
											label="Rate image"
											avatar={<Avatar>1-5</Avatar>}
										/>
									) : (
										""
									)}
									<Chip
										style={{ marginRight: 8 }}
										label="Download"
										avatar={<Avatar>D</Avatar>}
									/>
									<Chip
										style={{ marginRight: 8 }}
										label="Quality Image"
										avatar={<Avatar>Q</Avatar>}
									/>
									<Chip
										style={{ marginRight: 8 }}
										label="Info"
										avatar={<Avatar>I</Avatar>}
									/>
									<Chip
										style={{ marginRight: 8 }}
										label="Add to collection"
										avatar={<Avatar>C</Avatar>}
									/>
								</Toolbar>
							</div>
						) : null
					}
					sidebar={
						contribution ? (
							<div>
								<RemoveFromCollection
									open={this.state.showRemoveFromCollectionDialog}
									onSubmit={() => this.removeFromCollection(contribution)}
									onCancel={() =>
										this.setState({ showRemoveFromCollectionDialog: false })
									}
								/>
								<div
									className="buttons-container"
									style={{ marginBottom: 0, paddingBottom: 0 }}>
									<AddToCollection
										bindKey={"c"}
										addContributionsToCollection={addContributionToCollection}
									/>
									<IconButton
										onClick={downloadImage}
										className="download-button">
										<DownloadIcon />
									</IconButton>
								</div>
								{this.props.match.params.collectionSlug ? (
									<Button
										fullWidth
										style={{ color: "#F44336", marginTop: 10 }}
										rippleColor={"#F44336"}
										onClick={() =>
											this.setState({ showRemoveFromCollectionDialog: true })
										}>
										Remove from collection
									</Button>
								) : null}
								<ContributionDetails
									groups={ContributionDarwin[this.props.darwin]}
									darwinKey={this.props.darwin}
									categories={categories}
									contribution={contribution}
									updateContribution={updateContribution}
								/>
							</div>
						) : null
					}
					showSidebar={this.state.showDetailsPane}
				/>

				<Snackbar
					open={this.state.snackbarMessage.length > 0}
					message={this.state.snackbarMessage}
					autoHideDuration={2500}
					onClose={this.resetSnackbar}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					TransitionComponent={SnackbarTransition}
				/>
			</div>
		);
	}
}

export default Contribution;
