import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'

const Image = ({ url, size, containerStyle, imageStyle }) => {
  const containerStyleComputed = {
    position: 'relative',
    ...containerStyle
  }

  const imageStyleComputed = {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    ...imageStyle,
    backgroundSize: size,
    backgroundImage: `url(${url})`
  }

  const loaderStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: -1
  };

  return (
    <div style={containerStyleComputed}>
      <CircularProgress style={loaderStyle} />

      <div style={imageStyleComputed} />
    </div>
  )
}

Image.propTypes = {
  url: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['cover', 'contain']).isRequired,
  containerStyle: PropTypes.object,
  imageStyle: PropTypes.object
}

export default Image
