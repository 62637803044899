import React, { Component } from 'react'

import ContainerWithRightSidebar from '../components/ContainerWithRightSidebar'
import DisplayError from "../components/DisplayError";
import CollectionSettings from '../components/CollectionSettings';

import authentication from '../authentication'
import api from '../api'
import clone from "clone";
import cacheService from "../cacheService";

import CachedIcon from '@material-ui/icons/Cached'
import SettingsIcon from '@material-ui/icons/Settings';

import {
    List,
    ListItem,
    ListSubheader,
    ListItemText,
    CircularProgress,
    LinearProgress,
    GridList,
    GridListTile,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Toolbar,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    Popover,
    Input,
    Checkbox,
} from "@material-ui/core";

import Mousetrap from 'mousetrap'

const CollectionDetails = ({ collection }) => (
  <List>
    <ListSubheader>Details</ListSubheader>

    <ListItem
      disabled
      primaryText="Name"
      secondaryText={collection.title}
    />
  </List>
)

class Collections extends Component {
  constructor(props) {
    super(props)

    this.state = {
      collections: [],
      selectedCollections: [],
      isLoading: true,
      currentSearch: "",
      createCollectionModal: false,
      cache: {
        date: ""
      },
      error: {
        show: false,
        message: "",
        code: 0
      },
      categories: props.categories,
      sort: [
        {
          title: "Alphabetical",
          func: function(a, b){
            return a.title.localeCompare(b.title);
          }
        },
        {
          title: "Newest first",
          func: function(a, b){
            let dateA = new Date(a.created_on).getTime();
            let dateB = new Date(b.created_on).getTime();
            if(!a.created_on){
              dateA = 0;
            }
            if(!b.created_on){
              dateB = 0;
            }
            return dateB - dateA;
          }
        },
        {
          title: "Oldest first",
          func: function(a, b){
            let dateA = new Date(a.created_on).getTime();
            let dateB = new Date(b.created_on).getTime();
            if(!a.created_on){
              dateA = 0;
            }
            if(!b.created_on){
              dateB = 0;
            }
            return dateA - dateB;
          }
        }
      ],
      currentSort: 0,
      filters: {
        channels: [
            {
              title: "No filter",
              func: function(collection){
                return collection;
              }
            },
            {
              title: "Channel Web",
              func: function(collection){
                return collection && collection.channels && collection.channels.indexOf("web") > -1
              }
            },
            {
              title: "Channel Office Addin",
              func: function(collection){
                return collection && collection.channels && collection.channels.indexOf("office_addin") > -1
              }
            },
            {
              title: "Channel IBC",
              func: function(collection){
                return collection && collection.channels && collection.channels.indexOf("ibc") > -1
              }
            },
            {
              title: "Channel EDU",
              func: function(collection){
                return collection && collection.channels && collection.channels.indexOf("edu") > -1
              }
            },
            {
              title: "Channel UWP",
              func: function(collection){
                return collection && collection.channels && collection.channels.indexOf("uwp") > -1
              }
            },
            {
              title: "Channel Sway",
              func: function(collection){
                return collection && collection.channels && collection.channels.indexOf("Sway") > -1
              }
            }
        ],
        type: [
            {
              title: "No filter",
              func: function(collection){
                return collection;
              }
            },
            {
                title: "Featured",
                func: function(collection){
                    return collection && collection.featured
                }
            },
            {
                title: "Promoted",
                func: function(collection){
                    return collection && collection.promoted
                }
            },
            {
                title: "Hero",
                func: function(collection){
                    return collection && collection.hero
                }
            }
        ]
      },
      currentFilters: {
        type: 0,
        channels: 0
      }
    }

    this.selectCollection = this.selectCollection.bind(this)
    this.changeTofullscreenMode = this.changeTofullscreenMode.bind(this)
    this.refreshCollections();
  }

  refreshCollections = async () => {
    this.setState({
      collections: "",
      resultCollections: "",
      isLoading: true
    }, async () => {
      try {
        let collections = await api.getAllUserCollections(authentication.getUser().id);
        await this.setCollections(collections);
        cacheService.set("collectionsPage", collections);
        this.applyFilters();
      } catch(e) {
        console.log(e);
        let response = {};
        try {
          response = await e.json();
        } catch(e){
          response.detail = "";
        }
        this.setState({
          error: {
            show: true,
            code: e.status || 0,
            details: response.detail,
            message: "Something went wrong"
          }
        })
      }
    });
  }

  async setCollections(collections){
    return new Promise((resolve) => {
        this.setState({ 
          collections: collections, 
          isLoading: false,
          resultCollections: collections,
          error: {
            show: collections.length === 0,
            message: "No collections found",
            code: 404,
            actions: (
              <Button variant="contained" color="primary" label="Create collection" onClick={() => this.setState({ createCollectionModal: true })}>Create collection</Button>
            )
          }
        }, () => {
            resolve();
        });
    });
  }

  componentDidMount() {
    this.props.changeTitle('Collections')

    if(cacheService.get("collectionsPage")){
        let cache = cacheService.get("collectionsPage");
        this.setCollections(cache.data);
      } else {
        this.refreshCollections();
    }
    
    Mousetrap.bind(['f'], () => this.state.selectedCollections.length > 0 && this.changeTofullscreenMode(this.state.selectedCollections[0]))
  }

  componentWillUnmount() {
    Mousetrap.unbind(['f'])
  }

  selectCollection(slug) {
    this.setState({ selectedCollections: this.state.selectedCollections.includes(slug) ? [] : [slug] })
  }

  changeTofullscreenMode(slug, force) {
    if (!force && (this.state.selectedCollections.length !== 1 || slug !== this.state.selectedCollections[0])) {
      return false
    }

    const urlParams = new URLSearchParams(this.props.location.search)



    window.open(`#/collections/${slug}/contributions?${urlParams.toString()}`);

    return false;
  }

  searchCollections = (event, val) => {
    this.setState({
      currentSearch: event.target.value
    }, () => {
      this.applyFilters();
    })
  }

  sortCollections = (val) => {
    let sort = this.state.sort[val];
    let result = clone(this.state.resultCollections);
    result = result.filter(col => {
      return col.created_on
    });
    result.sort(sort.func);
    this.setState({
      resultCollections: result,
      currentSort: val
    })
  }

  applyFilters = () => {
    let result = clone(this.state.collections);
    Object.keys(this.state.filters).forEach((filterKey) => {
        if(this.state.currentFilters[filterKey]){
            let filter = this.state.filters[filterKey][this.state.currentFilters[filterKey]];
            result = result.filter(filter.func);
        }
    });
    if(this.state.currentSearch){
      result = result.filter(row => row.title.toLowerCase().indexOf(this.state.currentSearch.toLowerCase()) > -1);
    }
    this.setState({
        resultCollections: result
    }, () => {
        this.sortCollections(this.state.currentSort);
    })
  }

  filterCollections = (val, filterKey) => {
    let currentFilters = clone(this.state.currentFilters);
    currentFilters[filterKey] = val;
    this.setState({
      currentFilters: currentFilters
    }, () => {
        this.applyFilters();
    });
  }

  createCollection = (event) => {
    if(event){
      event.preventDefault();
    }
    this.setState({
      createCollectionModal: false,
      loading: true
    }, () => {
      api.createUserCollection(this.state.newCollectionName).then(response => {
        this.refreshCollections();
        this.setState({ 
          loading: false,
          newCollectionName: null
        });
      });
    });
  }

  activateSettings = (obj, el) => {
    this.setState({ 
      settingsElement: el,
      settingsCollection: obj
    })
  }

  closeSettings = () => {
    this.setState({ 
      settingsCollection: false,
      settingsElement: ""
    })
  }

  saveSettings = async (newData) => {
    console.log(newData);

    if (newData.categories) {
      newData.categories = newData.categories.join();
    }
    
    await api.modifyCollection(this.state.settingsCollection.slug, newData);

    this.setState({
      settingsCollection: false
    });
    this.refreshCollections();

    /* TODO: Send reqeust to API. */
  }

  render() {
    const { collections, resultCollections, selectedCollections, isLoading, filters, currentFilters } = this.state;
    const { changeTofullscreenMode } = this;

    let sort = this.state.sort.map((type, key) => {
      return <MenuItem key={key} value={key}>{"Sort by: " + type.title}</MenuItem>
    })
    let filterToolbar = Object.keys(this.state.filters).map((filterKey) => {
      let filter = filters[filterKey];
      let options = filter.map((type, key) => {
        return <MenuItem key={key} value={key}>{"Filter by: " + type.title}</MenuItem>
      });
      return (
        <FormControl key={filterKey} style={{ width: "100%", maxWidth: 250, margin: "0 15px" }}>
          <InputLabel>Filter collections ({filterKey})</InputLabel>
          <Select
            onChange={ (event, key) => this.filterCollections(event.target.value, filterKey) }
            value={ currentFilters[filterKey] }
          >
            { options }
          </Select>
        </FormControl>
      )
    });

    let cache = cacheService.get("collectionsPage");
    let cacheText = "";

    if(cache){
        cacheText = "Loaded cache version from: " + cache.moment.format("YYYY-MM-DD HH:mm") + ". Click to refresh";
    }

    return (
      <ContainerWithRightSidebar
        stickyOffset={64}
        content={
          <div>
            <Dialog
              modal={false}
              open={this.state.createCollectionModal}
              onRequestClose={this.handleClose}
            >
              <DialogTitle>Create new collection</DialogTitle>
              <DialogContent>
                <form onSubmit={this.createCollection}>
                  <TextField
                    label="Name of the collection"
                    fullWidth
                    autoFocus
                    value={this.state.newCollectionName}
                    onChange={(event, val) => this.setState({ newCollectionName: event.target.value })}
                  />
                </form>
              </DialogContent>
              <DialogActions>
                <Button
                  label="Cancel"
                  style={{ marginRight: 10 }}
                  onClick={() => this.setState({ createCollectionModal: false })}
                >Cancel</Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.createCollection}
                >Create Collection</Button>
              </DialogActions>
            </Dialog>

            { this.state.error.show ? (
              <DisplayError details={this.state.error.details} message={this.state.error.message} code={this.state.error.code} actions={this.state.error.actions} />
            ) : (
              <div>
                <Toolbar style={{ background: "#EAEAEA", padding: 20 }}>
                  <TextField
                    label="Search collections"
                    fullWidth={true}
                    onChange={ this.searchCollections }
                  />
                  { filterToolbar }
                  <FormControl style={{ width: "100%", maxWidth: 250, margin: "0 15px" }}>
                    <InputLabel>Sort collections</InputLabel>
                    <Select
                      onChange={ (event) => this.sortCollections(event.target.value) }
                      value={ this.state.currentSort }
                    >
                      { sort }
                    </Select>
                  </FormControl>
                  <Button variant="contained" color="primary" onClick={() => this.setState({ createCollectionModal: true })} style={{ flexShrink: 0 }} >
                    Create new Collection
                  </Button>
                </Toolbar>

                { this.state.loading ? (<LinearProgress mode="indeterminate" />) : "" }

                { cache ? (<div className="indicator">
                    <Button style={{ color: cache.color }} onClick={ this.refreshCollections } icon={<CachedIcon />}>{cacheText}</Button>
                </div>) : "" }
                
                { isLoading ? (
                  <div className="loader">
                    <CircularProgress />
                  </div>
                ) : (
                  <div>

                    <Popover 
                      open={!!this.state.settingsCollection} 
                      anchorEl={this.state.settingsElement}
                      onClose={ () => this.closeSettings() }
                      anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
                      transformOrigin={{ horizontal: 'center', vertical: 'center' }}
                    >
                     { !!this.state.settingsCollection ? <CollectionSettings 
                        collection={this.state.settingsCollection} 
                        onClose={this.closeSettings} 
                        onSave={this.saveSettings} 
                        categoriesData={this.props.categories} /> : null }
                    </Popover>
                    
                    <div
                      style={{margin: 2}}
                      className="contribution-grid"
                    >
                      {resultCollections.map((collection, i) => (
                        <div
                          key={i}
                          className="contribution-image-wrapper"
                          style={{ width: '12.5%', paddingBottom: '12.5%'}}
                          ref={'collection_' + i}
                        >
                          <IconButton
                            className="collection-settings"
                            style={{ color: "#FFF" }}
                            onClick={ (event) => this.activateSettings(collection, this.refs['collection_' + i]) }
                          >
                            <SettingsIcon />
                          </IconButton>
                          { collection.public ? (<div className='collection-published'><svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/></svg></div>) : (<div></div>) }
                          <div
                            className={`contribution-image collection-image ${collection.custom_background && 'collection-hide-title'} ${selectedCollections.includes(collection.slug) && 'selected'}`}
                            style={{backgroundImage: `url(${collection.custom_background || collection.background_photo}`}}
                            onClick={() => changeTofullscreenMode(collection.slug, true)}
                            title={collection.title}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )} { /* End no error */ }

          </div>
        }
        sidebar={
          selectedCollections.length === 1 ? (
            <CollectionDetails collection={collections.find(c => c.slug === selectedCollections[0])} />
          ) : null
        }
        showSidebar={selectedCollections.length !== 0}
      />
    )
  }
}

export default Collections
