import React, { Component } from 'react'

import FullscreenIcon from '@material-ui/icons/Fullscreen'

import {
  GridList,
  GridListTile,
  IconButton,
  Tooltip
} from '@material-ui/core'

import Mousetrap from 'mousetrap'

class ContributionsGrid extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectMode: 'single' //single | single-multiple | multiple
    }

    this.changeSelectionMode = this.changeSelectionMode.bind(this)
  }

  componentDidMount() {
    Mousetrap.bind(['command', 'ctrl', 'shift'], this.changeSelectionMode, 'keydown')
    Mousetrap.bind(['command', 'ctrl', 'shift'], this.changeSelectionMode, 'keyup')
    Mousetrap.bind(['f'], () => this.props.changeTofullscreenMode(this.props.selectedContributions[0]))
  }

  componentWillUnmount() {
    Mousetrap.unbind(['command', 'ctrl'], 'keydown')
    Mousetrap.unbind(['command', 'ctrl'], 'keyup')
    Mousetrap.unbind(['f'])
  }

  changeSelectionMode(event) {
    if (event.type === 'keydown') {
      this.setState({ selectMode: event.key === 'Shift' ? 'multiple' : 'single-multiple' })
    } else {
      this.setState({ selectMode: 'single' })
    }

    return false
  }

  render() {
    const { contributions, selectedContributions, selectContribution, changeTofullscreenMode } = this.props
    return (
      <div padding={2}
        style={{margin: 2}}
        className="contribution-grid"
      >
        {contributions.map(contribution => (
          <div
            key={contribution.django_id}
            className="contribution-image-wrapper"
          >
            <div
              className={`contribution-image ${selectedContributions.includes(contribution.slug) ? 'selected' : ''}`}
              style={{backgroundImage: `url(${contribution.thumb})`}}
              onClick={event => selectContribution(contribution.slug, this.state.selectMode)}
            />
            <IconButton
              className="contribution-image-fullscreen-button"
              style={{ color: "#FFF" }}
              onClick={() => changeTofullscreenMode(contribution.slug, true)}
            >
              <FullscreenIcon />
            </IconButton>
          </div>
        ))}
      </div>
    )
  }
}

export default ContributionsGrid
